import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { useQueryExt, useMutationExt } from '../core/apolloHooks'
import uuid from 'uuid'
import { useParams } from '@reach/router'
import { useDataOrCreator } from '../core/useCreate'


export const create = (initData = {}) => ({
  login: '',
  name: '',
  password: '',
  tel: '',
  role_id: undefined,
  is_active: true,
  structure: [],
  id: uuid(),
  ...initData,
})

export const USER_LIST_FRAGMENT = `fragment userListFragment on User {
    id
    is_active
    login
    name
    tel
    role {
      local_name
      id
    }
    structure{
      id
      name
    }
    clientChildren {
      id
      name
      structure_id
      is_active
    }
  }`

export const GET_LIST = gql`
  query UsersList {
    users {
      ...userListFragment
    }
  }
  ${USER_LIST_FRAGMENT}
`

export const useGetList = () => {
  return useQueryExt(GET_LIST, {
    // Чтобы loading на fetchMore был актуальным
    notifyOnNetworkStatusChange: true,
    listPath: 'users',
  })
}

export const USER_FRAGMENT = `fragment user on User {
    id
    is_active
    login
    name
    tel
    history
    role {
      local_name
      id
    }
    structure{
      id
      name
    }
    clientChildren {
      id
      name
      structure_id
      is_active
      date_birthday
      date_contract
      services {
          servicetype_id
          discount
          date_start
          date_end
      }
    }
  }`

export const GET_ITEM = gql`
  query($id: String) {
    users(id: $id) {
      ...user
    }
  }
  ${USER_FRAGMENT}
`

export const useGetItem = id => {
  const result = useQueryExt(GET_ITEM, {
    variables: { id },
    skip: id === 'new',
    objectPath: 'users[0]',
  })

  const data = useDataOrCreator(result.data, create)

  return { ...result, data }
}

export const SAVE = gql`
  mutation PutUser($data: UserInput!) {
    user(user: $data) {
      ...user
    }
  }
  ${USER_FRAGMENT}
`

export const useSave = () => {
  const { id } = useParams()

  // TODO update form data after save
  const [mutate, result] = useMutationExt(SAVE, {
    refetchQueries: id === 'new' && [{ query: GET_LIST }],
    awaitRefetchQueries: true,
  })

  const save = useCallback(
    async data => {
      await mutate({ variables: { data } })
    },
    [mutate]
  )

  return [save, result]
}
