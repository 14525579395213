import React, { useCallback, useMemo } from 'react'
import { DatePicker } from 'antd'
import { withMoment, ReportFilter, SelectWithOptions, Icon } from '../../components/Inputs'
import useFilters from '../../core/useFilter'
import { useDictsForSelects } from '../../api/dicts'

const DateInput = withMoment(DatePicker)

export const AccountsBalancesFilters = ({ navigate }) => {
  const { filters, setFilters } = useFilters('AccountsMovement')
  // Dicts
  const {
    data: { accounts },
    dictsLoading,
    dictsError,
  } = useDictsForSelects()

  const accountId = useMemo(() => filters.accountId && parseInt(filters.accountId), [
    filters.accountId,
  ])

  const dateOnChange = useCallback(value => setFilters({ ...filters, date: value }), [
    setFilters,
    filters,
  ])

  const navigateToAccount = useCallback(
    id => {
      setFilters({ ...filters, accountId: id })
      const goBack = accountId ? '../' : ''
      navigate(`${goBack}${id ? id.toString() : ''}`)
    },
    [navigate, accountId, filters, setFilters]
  )

  return (
    <>
      <ReportFilter label='На дату'>
        <DateInput
          placeholder='Выберите дату'
          onChange={dateOnChange}
          value={filters.date}
          endDay={true}
        />
      </ReportFilter>

      <ReportFilter label='Счет'>
        <SelectWithOptions
          allowClear
          showSearch
          options={accounts}
          onChange={navigateToAccount}
          placeholder='Выберите счет'
          value={accountId}
        />
      </ReportFilter>
    </>
  )
}
