import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { useQueryExt, useMutationExt, useRemoveExt } from '../core/apolloHooks'
import { useParams } from '@reach/router'
import uuid from 'uuid'
import moment from 'moment'
import useFilters from '../core/useFilter'
import { useDataOrCreator } from '../core/useCreate'

const create = (initData = {}) => ({
  id: uuid(),
  date: new moment(),
  user: undefined,
  servicetype_id: undefined,
  structure_id: undefined,
  clientChildrenIds: [],
  ...initData,
})

export const LIST_FRAGMENT = gql`
  fragment visitLists on VisitList {
    id
    date
    user {
      name
    }
    servicetype {
      id
      name
    }
    childrenCount
    structure {
      id
      name
    }
  }
`

export const FRAGMENT = gql`
  fragment visitList on VisitList {
    id
    date
    user {
      id
      name
    }
    servicetype {
      id
      name
    }
    childrenCount
    clientChildren {
      id
    }
    structure_id
    structure {
      id
      name
    }
  }
`

export const GET_LIST = gql`
  query VisitListsList($filters: Filter) {
    visitLists(filters: $filters) {
      ...visitLists
    }
  }
  ${LIST_FRAGMENT}
`

const useRefetchVariables = () => {
  const { filters } = useFilters('VisitLists')

  return {
    filters: {
      date: [
        { operator: '>=', value: filters.date[0] },
        { operator: '<=', value: filters.date[1] },
      ],
    },
  }
}

export const useGetList = () => {
  const variables = useRefetchVariables()

  return useQueryExt(GET_LIST, {
    variables,
    notifyOnNetworkStatusChange: true,
    listPath: 'visitLists',
  })
}

export const GET_ITEM = gql`
  query($id: String) {
    visitLists(id: $id) {
      ...visitList
    }
  }
  ${FRAGMENT}
`
export const useGetItem = id => {
  const result = useQueryExt(GET_ITEM, {
    variables: { id },
    skip: id === 'new',
    objectPath: 'visitLists[0]',
  })

  const data = useDataOrCreator(result.data, create)
  
  return { ...result, data }
}

export const SAVE = gql`
  mutation SaveVisitList($data: VisitListInput!) {
    visitList(visitList: $data) {
      ...visitList
    }
  }
  ${FRAGMENT}
`

export const useSave = () => {
  const { id } = useParams()
  const variables = useRefetchVariables()

  const [mutate, result] = useMutationExt(SAVE, {
    awaitRefetchQueries: true,
    refetchQueries: id === 'new' && [
      {
        query: GET_LIST,
        variables,
      },
    ],
  })

  // Prepare object: delete user
  const save = useCallback(
    async ({ user, structure, childrenCount, ...data }) => {
      await mutate({
        variables: { data },
      })
    },
    [mutate]
  )

  return [save, result]
}

export const REMOVE = gql`
  mutation($id: String!) {
    deleteVisitList(id: $id)
  }
`

export const useRemove = id => {
  return useRemoveExt(REMOVE, {
    variables: { id },
    fieldName: 'VisitList',
  })
}
