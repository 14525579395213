import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { useQueryExt } from '../core/apolloHooks'

const GET_LIST = gql`
  query {
    cameras {
      id
      src
      description
    }
  }
`

export const useGetList = () => {
  const queryResult = useQueryExt(GET_LIST, {
    notifyOnNetworkStatusChange: true,
    listPath: 'cameras',
  })

  const data = useMemo(() => {
    const { data } = queryResult

    return data ? data.map(c => ({ ...c, src: c.src.replace('&amp;', '&') })) : null
  }, [queryResult])

  return { ...queryResult, data }
}
