import { ApolloClient } from '@apollo/client'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import cache from './cache'
import { DEVELOPMENT, REACT_APP_PROD } from './env'

const uri =
  DEVELOPMENT && !REACT_APP_PROD
    ? 'http://localhost:4001/graphql'
    : REACT_APP_PROD
    ? 'https://добрые-традиции.easy-kids.ru/graphql'
    : '/graphql'

export const typeDefs = null
export const resolvers = {}

const httpLink = createHttpLink({
  uri,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? token : null,
      'client-name': 'Space Explorer [web]',
      'client-version': '1.0.0',
    },
  }
})

const client = new ApolloClient({
  cache,
  uri,
  link: authLink.concat(httpLink),
  typeDefs,
  resolvers: {},
  connectToDevTools: DEVELOPMENT,
})

export default client
