import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { useQueryExt, useMutationExt, useRemoveExt } from '../core/apolloHooks'
import { useParams } from '@reach/router'
import uuid from 'uuid'
import useFilters from '../core/useFilter'
import { useDataOrCreator } from '../core/useCreate'


const create = (initData={}) => ({
  id: uuid(),
  date: new Date(),
  accounting_period: new Date(),
  user: undefined,
  account_id: undefined,
  recipient_id: undefined,
  sum: undefined,
  ...initData,
})

export const FRAGMENT = gql`
  fragment salary on Salary {
    id
    date
    accounting_period
    sum
    comment

    user {
      id
      name
    }
    account {
      id
      name
    }
    recipient {
      id
      name
      is_active
    }
  }
`

export const GET_LIST = gql`
  query SalariesList($filters: Filter) {
    salaries(filters: $filters) {
      ...salary
    }
  }
  ${FRAGMENT}
`

const useRefetchVariables = () => {
  const { filters } = useFilters('Salaries')

  return {
    filters: {
      date: [
        { operator: '>=', value: filters.date[0] },
        { operator: '<=', value: filters.date[1] },
      ],
    },
  }
}

export const useGetList = () => {
  const variables = useRefetchVariables()

  return useQueryExt(GET_LIST, {
    variables,
    notifyOnNetworkStatusChange: true,
    listPath: 'salaries',
  })
}

export const GET_ITEM = gql`
  query($id: String) {
    salaries(id: $id) {
      ...salary
    }
  }
  ${FRAGMENT}
`
export const useGetItem = id => {
  const result = useQueryExt(GET_ITEM, {
    variables: { id },
    skip: id === 'new',
    objectPath: 'salaries[0]',
  })

  const data = useDataOrCreator(result.data, create)

  return { ...result, data }
}

export const SAVE = gql`
  mutation SaveSalary($data: SalaryInput!) {
    salary(salary: $data) {
      ...salary
    }
  }
  ${FRAGMENT}
`

export const useSave = () => {
  const { id } = useParams()
  const variables = useRefetchVariables()

  const [mutate, result] = useMutationExt(SAVE, {
    awaitRefetchQueries: true,
    refetchQueries: id === 'new' && [
      {
        query: GET_LIST,
        variables,
      },
    ],
  })

  // Prepare object: delete user
  const save = useCallback(
    async ({ user, ...data }) => {
      await mutate({
        variables: { data },
      })
    },
    [mutate]
  )

  return [save, result]
}

export const REMOVE = gql`
  mutation($id: String!) {
    deleteSalary(id: $id)
  }
`

export const useRemove = id => {
  return useRemoveExt(REMOVE, {
    variables: { id },
    fieldName: 'Salary',
  })
}
