import React, { useCallback, useMemo } from 'react'
import { DatePicker } from 'antd'
import { withMoment, ReportFilter, SelectWithOptions, Icon } from '../../components/Inputs'
import useFilters from '../../core/useFilter'
import { useDictsForSelects } from '../../api/dicts'

const DateInput = withMoment(DatePicker)

export const ClientsBalancesFilters = ({ navigate }) => {
  const { filters, setFilters } = useFilters('ClientsAccountsMovement')
  // Dicts
  const {
    data: { clients },
  } = useDictsForSelects()

  const dateOnChange = useCallback(value => setFilters({ ...filters, date: value }), [
    setFilters,
    filters,
  ])

  const navigateToClient = useCallback(
    id => {
      setFilters({ ...filters, userId: id })
      const goBack = filters.userId ? '../' : ''
      navigate(`${goBack}${id ? id.toString() : ''}`)
    },
    [filters.userId, navigate, filters, setFilters]
  )

  const userId = useMemo(() => filters.userId, [filters, filters.userId])

  return (
    <>
      <ReportFilter label='На дату'>
        <DateInput
          placeholder='Выберите дату'
          onChange={dateOnChange}
          value={filters.date}
          endDay={true}
        />
      </ReportFilter>

      <ReportFilter label='Клиент'>
        <SelectWithOptions
          allowClear
          showSearch
          options={clients}
          onChange={navigateToClient}
          placeholder='Выберите клиента'
          value={userId}
        />
      </ReportFilter>
    </>
  )
}
