import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { useQueryExt } from '../core/apolloHooks'
import useFilters from '../core/useFilter'
import { round } from 'lodash'
export const FRAGMENT = gql`
  fragment clientAccountsMovement on ClientsAccountsMovement {
    sum
    user {
      id
      name
      is_active
    }
  }
`

//Group by clients
export const GET_LIST = gql`
  query ($filters: Filter) {
    balance: clientsAccountsMovements(filters: $filters, grouping: ["user_id"]) {
      ...clientAccountsMovement
    }
  }
  ${FRAGMENT}
`

const useRefetchVariables = () => {
  const { filters } = useFilters('ClientsAccountsMovement')

  return {
    filters: {
      date: { operator: '<', value: filters.date },
    },
  }
}

export const useGetList = () => {
  const variables = useRefetchVariables()
  const queryResult = useQueryExt(GET_LIST, {
    variables,
    notifyOnNetworkStatusChange: true,
    listPath: 'balance',
  })

  const data = useMemo(() => {
    const { data } = queryResult
    return data && data.length ? data.map(b => ({ ...b, sum: round(b.sum, 2) })) : []
  }, [queryResult.data])

  return { ...queryResult, data }
}

//History
export const GET_CLIENT_HISTORY = gql`
  query ($filters: Filter, $userFilters: Filter) {
    clientsAccountsMovements(filters: $filters, orderBy: { column: "date", sort: "desc" }) {
      sum
      date
      owner_table_name
      owner_id
      description
      clientChildren {
        id
        name
      }
      servicetype {
        id
        name
      }
    }

    clientChildren(filters: $userFilters) {
      value: id
      text: name
    }
  }
`

const useRefetchHistoryVariables = () => {
  const { filters } = useFilters('ClientsAccountsMovement')

  return {
    filters: {
      date: [
        { operator: '<', value: filters.date },
        { operator: '>=', value: filters.dateFromHistory },
      ],
      user_id: filters.userId,
    },
    userFilters: { parent_id: filters.userId },
  }
}

export const useGetClientHistory = () => {
  const variables = useRefetchHistoryVariables()

  const queryResult = useQueryExt(GET_CLIENT_HISTORY, {
    variables,
    notifyOnNetworkStatusChange: true,
  })

  const data = useMemo(() => {
    const { clientsAccountsMovements: data = [], clientChildren = [] } = queryResult.data || {}
    return { data: data.map(b => ({ ...b, sum: round(b.sum, 2) })), clientChildren }
  }, [queryResult.data])

  return { ...queryResult, ...data }
}

// Visit List Posts
export const GET_VISIT_LIST_POSTS = gql`
  query ($filters: Filter) {
    clientsAccountsMovements(filters: $filters, orderBy: { column: "date", sort: "desc" }) {
      sum
      date
      description
      user {
        name
      }
      clientChildren {
        id
        name
      }
      servicetype {
        name
      }
    }
  }
`

export const useGetVisitListPosts = id => {
  const queryResult = useQueryExt(GET_VISIT_LIST_POSTS, {
    variables: { filters: { owner_id: id } },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: !id,
  })

  const data = useMemo(() => {
    const { clientsAccountsMovements } = queryResult.data || {}
    console.log(clientsAccountsMovements || [])
    return clientsAccountsMovements || []
  }, [queryResult.data])

  return data
}
