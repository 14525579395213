import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { useQueryExt, useMutationExt, useRemoveExt } from '../core/apolloHooks'
import { useParams } from '@reach/router'
import uuid from 'uuid'
import { useProfile } from '../api/profile'
import { useDataOrCreator } from '../core/useCreate'


const ADMIN_LIMIT = 30
const COMMON_LIMIT = 10

export const create = (init = {}) => ({
  title: '',
  recipients: [],
  content: '',
  date: new Date(),
  id: uuid(),
  ...init,
})

export const FRAGMENT = `fragment news on News{
	id
    date
    author_name
    title
    content
    recipients{
      id
      name
    }
}`

export const GET_LIST = gql`
  query NewsList($limit: Int, $offset: Int) {
    news(limit: $limit, offset: $offset) {
      ...news
    }
  }
  ${FRAGMENT}
`

const useRefetchVariables = () => {
  const { IS_ADMIN } = useProfile()

  return {
    limit: IS_ADMIN ? ADMIN_LIMIT : COMMON_LIMIT,
    offset: 0,
  }
}

export const useGetList = () => {
  const variables = useRefetchVariables()
  return useQueryExt(GET_LIST, {
    variables,
    notifyOnNetworkStatusChange: true,
    listPath: 'news',
  })
}

export const GET_ITEM = gql`
  query($id: String) {
    news(id: $id) {
      ...news
    }
  }
  ${FRAGMENT}
`

export const useGetItem = id => {
  const result = useQueryExt(GET_ITEM, {
    variables: { id },
    skip: id === 'new',
    objectPath: 'news[0]',
  })

  const data = useDataOrCreator(result.data, create)

  return { ...result, data }
}

export const SAVE = gql`
  mutation($data: NewsInput!) {
    news(news: $data) {
      ...news
    }
  }
  ${FRAGMENT}
`

export const useSave = () => {
  const variables = useRefetchVariables()
  const { id } = useParams()
  const {
    currentUser: { id: user_id },
  } = useProfile()

  const [mutate, result] = useMutationExt(SAVE, {
    awaitRefetchQueries: true,
  })

  // Prepare object: delete author_name
  const save = useCallback(
    async ({ author_name, ...data }, options = {}) => {
      await mutate({
        ...options,
        // Изменим автора на текущего пользователя
        variables: { data: { ...data, user_id } },
        // Перезапросить список при сохранении дубликата или нового -
        // Т.е. при сохранении с id, отличным от id в url
        refetchQueries: id !== data.id && [
          {
            query: GET_LIST,
            variables,
          },
        ],
      })
    },
    [mutate, user_id, id, variables]
  )

  return [save, result]
}

export const REMOVE = gql`
  mutation($id: String!) {
    deleteNews(id: $id)
  }
`

export const useRemove = id => {
  return useRemoveExt(REMOVE, {
    variables: { id },
    fieldName: 'News',
  })
}
