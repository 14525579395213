import React, { useCallback, useMemo } from 'react'
import { Table } from '../../../components/Inputs'

import { TitleFixed } from '../../../components/PageTemplate'
import { Button, ButtonGroup, DatesFilterDropdownProps } from '../../../components/Inputs'

import { ErrorIcon } from '../../../components/Icons'
import useFilters from '../../../core/useFilter'
import { useGetList } from '../../../api/transfers'
import { useDictsForFilters } from '../../../api/dicts'

const Transfers = ({ navigate }) => {
  // Data
  const { data, loading, error, refetch } = useGetList()

  // Callbacks
  const create = useCallback(() => navigate('/transfers/new'), [navigate])
  const update = useCallback(() => refetch(), [refetch])
  const edit = useCallback(row => navigate('/transfers/' + row.id), [navigate])

  // Dicts
  const {
    data: { accounts },
    dictsError,
  } = useDictsForFilters()

  // Filters and Columns
  const { filters, setFilters } = useFilters('Transfers')
  const columns = useMemo(
    () => [
      {
        ...DatesFilterDropdownProps(),
        filteredValue: filters['date'],
      },
      {
        filters: accounts,
        title: 'Счет списания',
        dataIndex: 'account.name',
        key: 'account.name',
        onFilter: (value, record) => record.account.id === value,
        filteredValue: filters['account.name'],
      },
      {
        filters: accounts,
        title: 'Счет-получатель',
        dataIndex: 'account_to.name',
        key: 'account_to.name',
        onFilter: (value, record) => record.account_to.id === value,
        filteredValue: filters['account_to.name'],
      },
      {
        title: 'Сумма',
        dataIndex: 'sum',
        key: 'sum',
      },
    ],
    [filters, accounts]
  )

  if (error || dictsError) return <ErrorIcon />

  return (
    <>
      <TitleFixed
        text='Переводы'
        commands={
          <ButtonGroup>
            <Button loading={loading} icon='sync' onClick={update}>
              Обновить
            </Button>
            <Button type='primary' icon='plus' onClick={create}>
              Создать
            </Button>
          </ButtonGroup>
        }
      />

      <Table
        loading={loading}
        dataSource={data}
        rowKey='id'
        onChange={(pagination, filters, sorter) => {
          setFilters(filters)
        }}
        onRow={record => ({ onClick: e => edit(record) })}
        columns={columns}
      />
    </>
  )
}

export default Transfers
