import React from 'react'

import { TitleFixed } from '../components/PageTemplate'
import s from '../widgets/widgets.module.css'

import {
  NewsMaker,
  DocsMaker,
  // BalanceMaker
} from '../widgets/Makers'
import VideoWidget from '../widgets/VideoWidget'

const AdminMain = () => {
  return (
    <>
      <TitleFixed>Главное</TitleFixed>
      <h3 className={s.widgetTitle} style={{ marginTop: 0 }}>
        Поделитесь чем-то с родителями
      </h3>
      <NewsMaker />

      {
        // <h3 className={s.widgetTitle}>Баланс</h3>
        // <BalanceMaker {...this.props}/>
      }

      <h3 className={s.widgetTitle}>Создание Документов</h3>
      <DocsMaker />

      <h3 className={s.widgetTitle}>Видеонаблюдение</h3>
      <VideoWidget />
    </>
  )
}

export default AdminMain
