import { useEffect, useState, useCallback } from 'react'

const useMobile = () => {
  const [mobile, setMobile] = useState()

  const setMobileCallback = useCallback(() => {
    setMobile(window.innerWidth < 768)
  }, [setMobile])

  useEffect(() => {
    setMobileCallback()
    window.addEventListener('resize', setMobileCallback)
    return () => {
      window.removeEventListener('resize', setMobileCallback)
    }
  }, [setMobileCallback])

  return mobile
}

export default useMobile
