import React, { useCallback, useMemo } from 'react'
import { DatesFilterDropdownProps, Table } from '../../components/Inputs'
import { useGetList, useGetClientHistory } from '../../api/clientBalances'
import useFilters from '../../core/useFilter'
import { makeDocLink } from '../../core/const'
import { ErrorIcon } from '../../components/Icons'
import { VISIT_LIST_POSTS_DESCRIPTION_TYPES } from '../../core/const'
import { useDictsForFilters } from '../../api/dicts'
import { useProfile } from '../../api/profile'

const ClientsHistory = ({ navigate, titleRender, balanceRender, showDocumentLink, ...props }) => {
  const {
    data: balanceData,
    loading: balanceLoading,
    error: balanceError,
    refetch: refetchBalance,
  } = useGetList()
  const { data, clientChildren, loading, error, refetch } = useGetClientHistory()
  const { filters, setFilters } = useFilters('ClientsAccountsMovement')
  const { IS_ADMIN } = useProfile()

  // Callbacks
  const update = useCallback(() => {
    refetch()
    refetchBalance()
  }, [refetch, refetchBalance])

  const setFilterDate = useCallback(
    value => setFilters({ ...filters, dateFromHistory: value[0] }),
    [filters, setFilters]
  )

  const rowKey = useCallback(
    record => record.owner_id + (record.clientChildren ? record.clientChildren.id : ''),
    []
  )

  const backButtonOnClick = useCallback(() => {
    setFilters({ ...filters, userId: undefined })
    navigate('../')
  }, [filters, setFilters, navigate])

  // Dicts
  const {
    data: { servicesTypes },
  } = useDictsForFilters()

  const servicesTypesFiltered = useMemo(
    () =>
      servicesTypes && servicesTypes.length
        ? servicesTypes
            .filter(i => !i.is_parent_servicetype)
            .sort((a, b) => (a.is_parent_servicetype && !b.is_parent_servicetype ? -1 : 1))
        : [],
    [servicesTypes]
  )

  //state
  const userId = useMemo(() => {
    //если страница была перезагружена то нужно отправить в фильтры айди заново
    if (!filters.userId) {
      setFilters({ ...filters, userId: props.id })
    }
    return filters.userId || props.id
  }, [filters, props.id, setFilters])

  //баланс выбранного счета
  const balance = useMemo(
    () => (balanceLoading || balanceError ? '' : balanceData.find(b => b.user.id === userId).sum),
    [balanceData, balanceLoading, balanceError, userId]
  )

  const columns = useMemo(
    () =>
      [
        {
          ...DatesFilterDropdownProps(setFilterDate, [false, true]),
          filteredValue: [filters.dateFromHistory, filters.date],
        },
        {
          title: 'Руб',
          dataIndex: 'sum',
          key: 'sum',
          align: 'right',
          onFilter: (value, record) => (value ? record.sum > 0 : record.sum <= 0),
          filters: [
            { text: 'Приход', value: true },
            { text: 'Расход', value: false },
          ],
          render: val => (
            <span
              style={{
                color: val > 0 ? 'var(--c-positive)' : 'var(--c-danger)',
              }}
            >
              {val}
            </span>
          ),
        },
        {
          title: 'Пояснение',
          dataIndex: 'description',
          key: 'description',
          filters: VISIT_LIST_POSTS_DESCRIPTION_TYPES.map(i => ({
            value: i,
            text: i,
          })),
          onFilter: (value, record) => record.description === value,
        },
        {
          title: 'Услуга',
          dataIndex: 'servicetype.name',
          key: 'servicetype.name',
          filters: servicesTypesFiltered,
          onFilter: (value, record) => record.servicetype && record.servicetype.id === value,
        },

        clientChildren &&
          clientChildren.length > 1 && {
            title: 'Ребенок',
            dataIndex: 'clientChildren.name',
            key: 'clientChildren.name',
            filters: clientChildren,
            onFilter: (value, record) =>
              record.clientChildren && record.clientChildren.id === value,
          },

        showDocumentLink && {
          title: 'Документ',
          dataIndex: 'docLink',
          key: 'docLink',
          render: (val, row) => makeDocLink(row, IS_ADMIN),
        },
      ].filter(col => col),
    [filters, setFilterDate, clientChildren, servicesTypesFiltered, IS_ADMIN]
  )

  return (
    <>
      {titleRender({ update, loading, navigate, showBackBtn: true, backButtonOnClick })}

      {balanceRender({ balance })}

      {(error && <ErrorIcon />) || (
        <Table dataSource={data} columns={columns} rowKey={rowKey} loading={loading} />
      )}
    </>
  )
}

export default ClientsHistory
