import React, { useEffect } from 'react'
import { LoaderProgress } from '../components/Icons'
import { useGetProfile, useProfile } from '../api/profile'

const withAuthChecker = (LoginPage, Pages) => () => {
  const { IS_AUTHORIZED } = useProfile()
  const [getProfile, { called, error }] = useGetProfile()
  const token = localStorage.getItem('token')

  // Если пользователь зашел на страницу, еще не аутентифицировался,
  // но у него есть токен-запросим профиль, если он еще не был запрошен
  useEffect(() => {
    if (token && !called) {
      getProfile()
    }
  }, [called, token, getProfile])

  // Если авторизован - отрисовывем
  if (IS_AUTHORIZED) {
    return <Pages />
  } else {
    // Если нет токена - отрисуем LoginPage с колбеком на запрос профиля
    // Либо если при загрузке профиля на странице где уже был токен произошла ошибка при запросе профиля
    if (!token || error) {
      return <LoginPage onSuccess={getProfile} />
    }

    // Все остальное можно считать загрузкой: в это время запрашивается профиль
    // а при ошибке запроса профиля useGetProfile удалит токен и отобразится LandinPage
    return <LoaderProgress />
  }
}

export default withAuthChecker
