import { useCallback, useMemo, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from '@reach/router'
import { setCreateInit } from '../cache'
import { gql } from '@apollo/client'
import { useQueryExt } from '../core/apolloHooks'

import get from 'lodash.get'

export const GET_CREATE_INIT = gql`
  query GetCreateInit {
    createInit @client
  }
`

const useCreate = url => {
  const navigate = useNavigate()
  const location = useLocation()
  const { data } = useQueryExt(GET_CREATE_INIT)

  url = useMemo(() => (url || location.href) + '/new', [url, location])

  const create = useCallback(
    initCreateData => {
      setCreateInit({ ...data, [url]: initCreateData })
      navigate(url)
    },
    [navigate, url, data]
  )

  return create
}

export default useCreate

export const useCreateInit = () => {
  const { id } = useParams()
  const { pathname } = useLocation()

  const { data } = useQueryExt(GET_CREATE_INIT, {
    skip: id !== 'new',
  })

  // clear init data on remount
  useEffect(() => {
    return () => {
      setCreateInit({ ...data, [pathname]: null })
    }
  }, [data, pathname])

  if (id === 'new') {
    // get init data
    const createInit = get(data, 'createInit.' + pathname, {})
    return createInit
  }
  //return undefined if id!=='new'
}

export const useDataOrCreator = (data, creator) => {
  const { id } = useParams()

  const createInit = useCreateInit()

  const result = useMemo(() => (id === 'new' ? creator(createInit) : data), [id, data, creator, createInit])

  return result
}
