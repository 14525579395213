import React, { useCallback, useMemo } from 'react'
import { useNavigate } from '@reach/router'
import ReactSwipeEvents from 'react-swipe-events'

import moment from 'moment'
import { Card } from 'antd'
import { TitleFixed } from '../../components/PageTemplate'
import { Button, HTMLContainer, ButtonGroup } from '../../components/Inputs'

import { useProfile } from '../../api/profile'
import { useGetList, useRemove } from '../../api/news'
import { LoaderProgress, ErrorIcon } from '../../components/Icons'

import s from './style.module.css'

const { Meta } = Card

const NewsList = ({ navigate }) => {
  const { IS_ADMIN, IS_EMPLOYEE } = useProfile()

  const { data, loading, error, refetch, fetchMore } = useGetList()

  // Callbacks
  const create = useCallback(() => navigate('/news/new'), [navigate])
  const update = useCallback(() => refetch(), [refetch])

  // Fetch more callback
  const more = useCallback(() => {
    const offset = data && data.length
    fetchMore({
      variables: { offset },
      //TODO: deprecated: Please convert updateQuery functions to field policies with appropriate read and merge functions
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return { news: [...prev.news, ...fetchMoreResult.news] }
      },
    })
  }, [fetchMore, data])

  return (
    <>
      <TitleFixed
        text='Новости'
        commands={
          <ButtonGroup>
            <Button loading={loading} icon='sync' onClick={update}>
              Обновить
            </Button>
            {IS_ADMIN || IS_EMPLOYEE ? (
              <Button type='primary' icon='plus' onClick={create}>
                Создать
              </Button>
            ) : null}
          </ButtonGroup>
        }
      />

      {loading && !data.length && <LoaderProgress />}
      {error && <ErrorIcon />}

      <ReactSwipeEvents
        onSwipedDown={(e, x, y) => {
          if (window.scrollY <= 0) {
            // refetch()
          }
        }}
        onSwipedUp={() => {
          if (window.scrollY >= document.documentElement.scrollHeight) {
            alert('true')
            // loadMore()
          }
        }}
      >
        <>
          {data.map(item => {
            return <NewsListItem key={item.id} item={item} />
          })}
        </>
      </ReactSwipeEvents>

      {data.length > 0 && (
        <div className={s.newsMore}>
          <Button loading={loading} type='primary' icon='download' onClick={more}>
            Еще...
          </Button>
        </div>
      )}
    </>
  )
}

export default NewsList

const NewsListItem = ({ item, ...props }) => {
  const { IS_ADMIN, IS_EMPLOYEE } = useProfile()

  const navigate = useNavigate()

  const edit = useCallback(() => {
    navigate('/news/' + item.id)
  }, [navigate, item])

  const [remove, { loading: removeLoading }] = useRemove(item.id)

  const description = useMemo(
    () =>
      `${new moment(item.date).format('DD MMM')} | ${
        IS_ADMIN || IS_EMPLOYEE ? item.recipients.map(c => c.name).join(', ') : item.author_name
      }`,
    [IS_ADMIN, IS_EMPLOYEE, item]
  )

  return (
    <Card
      className={s.card}
      type='inner'
      title={<Meta title={item.title} description={description} />}
      extra={
        IS_ADMIN || IS_EMPLOYEE ? (
          <div className={s.commands}>
            <Button type='danger' icon='delete' onClick={remove} loading={removeLoading} />
            <Button type='primary' icon='edit' onClick={edit} />
          </div>
        ) : null
      }
    >
      <HTMLContainer html={item.content} />
    </Card>
  )
}
