import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { useQueryExt } from '../core/apolloHooks'
import get from 'lodash.get'
import moment from 'moment'

import { setFilters } from '../cache'

// Init store
const start3MonthDate = moment().startOf('day').subtract(2, 'months')
const endMonthDate = moment().endOf('day')

export const initialFilters = {
  // Docs
  Salaries: { date: [start3MonthDate, endMonthDate] },
  PaymentReceipts: { date: [start3MonthDate, endMonthDate] },
  Payments: { date: [start3MonthDate, endMonthDate] },
  Transfers: { date: [start3MonthDate, endMonthDate] },
  Freezings: { date: [start3MonthDate, endMonthDate] },
  ManualAdjustments: { date: [start3MonthDate, endMonthDate] },
  VisitLists: { date: [start3MonthDate, endMonthDate] },
  AccountsMovement: { date: endMonthDate, dateFromHistory: start3MonthDate },
  ClientsAccountsMovement: { date: endMonthDate, dateFromHistory: start3MonthDate },
}
setFilters(initialFilters)

export const GET_FILTERS = gql`
  query GetFilters {
    filters @client
  }
`

const useFilters = tableName => {
  const { data } = useQueryExt(GET_FILTERS)
  const filters = get(data, 'filters.' + tableName, {})

  const setTableFilters = useCallback(
    tableFilters => {
      setFilters({ ...data.filters, [tableName]: tableFilters })
    },
    [data, tableName]
  )

  const setDefaultFilter = () => setTableFilters(initialFilters[tableName])

  return { filters, setFilters: setTableFilters, setDefaultFilter }
}

export default useFilters
