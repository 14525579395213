import React, { useMemo, useCallback } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import { TitleFixed } from '../../components/PageTemplate'
import {
  FormItem,
  Input,
  Button,
  ButtonGroup,
  RichText,
  confirm,
  TableMultiSelect,
  TableSearchDropdownProps,
} from '../../components/Inputs'
import { LoaderProgress, ErrorIcon } from '../../components/Icons'

import { create, useGetItem, useSave, useRemove } from '../../api/news'
import { useDictsForFilters, useChildForNews } from '../../api/dicts'
import { useNavigate } from '@reach/router'

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Обязательное поле!'),
  recipients: Yup.array().min(1, 'Укажите получателей').required('Обязательное поле!'),
})

const NewsForm = ({ id }) => {
  const navigate = useNavigate()

  const { data, loading, error } = useGetItem(id)
  const [save, { loading: saveLoading }] = useSave()
  const [remove, { loading: removeLoading }] = useRemove(id)

  // Map recipients objects to ids
  const news = useMemo(() => {
    return (
      data && {
        ...data,
        recipients: data.recipients.map(child => child.id),
      }
    )
  }, [data])

  // Дети, доступные пользователю
  const { data: clientChildren, childLoading, childError } = useChildForNews()

  // Структуры, доступные пользователю
  const {
    data: { structure },
    dictsLoading,
    dictsError,
  } = useDictsForFilters()

  // Столбцы для таблицы детей
  const childColumns = useMemo(() => {
    return [
      {
        ...TableSearchDropdownProps('label'),
        title: 'Ребенок',
        dataIndex: 'label',
        key: 'label',
      },
      structure && structure.length > 1
        ? {
            title: 'Группа',
            dataIndex: 'structure.name',
            key: 'structure.name',
            filters: structure,
            onFilter: (value, record) => value === record.structure.id,
          }
        : null,
    ].filter(i => i)
  }, [structure])

  // Колбек дублирования
  const dublicate = useCallback(
    async ({ values: { id, date, recipients, ...init }, dirty }) => {
      if (dirty) {
        try {
          await confirm({
            title: 'Изменения не были сохранены',
            content: 'Уверены, что хотите продолжить?',
            okType: 'danger',
          })
        } catch {
          return
        }
      }
      //Отчистим неактивных детей из получателей
      recipients = recipients.filter(id => clientChildren.find(({ value }) => value === id))

      const newValues = create({ ...init, recipients, date: new Date() })
      await save(newValues)
      navigate('/news/' + newValues.id)
    },
    [save, navigate, clientChildren]
  )

  if (loading || dictsLoading || childLoading) return <LoaderProgress />
  if (error || dictsError || childError) return <ErrorIcon />

  return (
    <Formik
      initialValues={news}
      onSubmit={save}
      validationSchema={validationSchema}
      validateOnChange={true}
    >
      {props => {
        return (
          <Form>
            <TitleFixed
              withBackBtn
              text={'Детей: ' + (props.values ? props.values.recipients.length : 0)}
              commands={
                <ButtonGroup>
                  {id === 'new' ? null : (
                    <>
                      <Button loading={removeLoading} icon='delete' type='danger' onClick={remove}>
                        Удалить
                      </Button>
                      <Button
                        icon='copy'
                        onClick={() => {
                          dublicate(props)
                        }}
                      >
                        Дублировать
                      </Button>
                    </>
                  )}
                  <Button loading={saveLoading} icon='save' type='primary' htmlType='submit'>
                    Сохранить
                  </Button>
                </ButtonGroup>
              }
            />

            <FormItem
              label='Заголовок'
              component={Input}
              name='title'
              placeholder='Заголовок новости'
            />

            <FormItem
              label='Контент'
              component={RichText}
              name='content'
              placeholder='Текст новости'
            />

            <FormItem
              label='Кто увидит'
              component={TableMultiSelect}
              name='recipients'
              options={clientChildren}
              columns={childColumns}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default NewsForm
