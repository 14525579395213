import React, { useMemo } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { TitleFixed } from '../../../components/PageTemplate'
import {
  Input,
  FormItem,
  Button,
  ButtonGroup,
  Select,
  DatePicker,
  InputNumber,
  MonthPicker,
  TextArea,
} from '../../../components/Inputs'
import { LoaderProgress, ErrorIcon } from '../../../components/Icons'

import { useDictsForSelects } from '../../../api/dicts'
import { useGetItem, useSave, useRemove } from '../../../api/salaries'

const validationSchema = Yup.object().shape({
  date: Yup.date().required('Обязательное поле!'),
  account_id: Yup.string().required('Обязательное поле!'),
  recipient_id: Yup.string().required('Обязательное поле!'),
  sum: Yup.number().required('Обязательное поле!').moreThan(0, 'Должно быть больше 0'),
  comment: Yup.string().max(8000, 'Слишком длинное!').nullable(),
})

const SalaryForm = ({ id }) => {
  // Data
  const { data, loading, error } = useGetItem(id)
  const [save, { loading: saveLoading }] = useSave()
  const [remove, { loading: removeLoading }] = useRemove(id)

  // Prepare
  const salary = useMemo(() => {
    if (data && id !== 'new') {
      const { account, user, recipient, ...salary } = data
      return {
        ...salary,
        recipient_id: recipient.id,
        account_id: account.id,
        user: user.name,
      }
    }
    return data
  }, [data, id])

  // Dicts
  const {
    data: { accounts, salaryRecipients },
    dictsLoading,
    dictsError,
  } = useDictsForSelects()

  if (loading || dictsLoading) return <LoaderProgress />
  if (error || dictsError) return <ErrorIcon />

  return (
    <Formik validationSchema={validationSchema} initialValues={salary} onSubmit={save}>
      {props => (
        <Form>
          <TitleFixed
            text='Зарплата'
            withBackBtn
            commands={
              <ButtonGroup>
                <Button loading={removeLoading} icon='delete' type='danger' onClick={remove}>
                  Удалить
                </Button>
                <Button loading={saveLoading} icon='save' type='primary' htmlType='submit'>
                  Сохранить
                </Button>
              </ButtonGroup>
            }
          />
          <FormItem
            label='Дата выдачи'
            component={DatePicker}
            name='date'
            placeholder='Выберите дату'
            allowClear={false}
          />

          <FormItem
            label='Период'
            component={MonthPicker}
            name='accounting_period'
            placeholder='Учетный период'
            allowClear={false}
          />

          <FormItem
            showSearch
            label='Сотрудник'
            component={Select}
            name='recipient_id'
            placeholder='Выберите сотрудника'
            options={salaryRecipients}
          />

          <FormItem
            label='Счет списания'
            component={Select}
            name='account_id'
            placeholder='Выберите счет'
            options={accounts}
          />

          <FormItem label='Сумма' component={InputNumber} name='sum' placeholder='Введите сумму' />

          <FormItem label='Комментарий' component={TextArea} name='comment' allowClear={true} />

          <FormItem
            disabled={true}
            label='Автор'
            component={Input}
            name='user'
            placeholder='Автор документа'
          />
        </Form>
      )}
    </Formik>
  )
}

export default SalaryForm
