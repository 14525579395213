import { notify } from '../components/Inputs'

const errorNotifier = err => {
  if (typeof err === 'string') {
    return notify({
      type: 'error',
      message: 'Ошибка',
      description: err,
    })
  }

  let { graphQLErrors, networkError } = err
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      notify({
        type: 'error',
        message: 'Ошибка',
        description: message,
      })
    )

  if (networkError) {
    networkError.result
      ? networkError.result.errors.map(({ message }) =>
          notify({
            type: 'error',
            message: 'Ошибка сети',
            description: message,
          })
        )
      : notify({
          type: 'error',
          message: 'Ошибка сети',
          description: 'Обратитесь к администратору',
        })
  }
}

export default errorNotifier
