import React, { useMemo, useCallback } from 'react'

import { List, Icon } from 'antd'

import { ErrorIcon } from '../components/Icons'
import { Collapse, CollapsePanel } from '../components/Inputs'

import useCreate from '../core/useCreate'
import { useDictsForSelects } from '../api/dicts'
import { useNavigate } from '@reach/router'

import s from './widgets.module.css'

export const VisitListMaker = () => {
  const {
    data: { servicesTypes },
    dictsError,
    dictsLoading,
  } = useDictsForSelects()

  const services = useMemo(() => {
    if (servicesTypes) {
      return servicesTypes
        .filter(i => !i.parent_servicetype_id)
        .sort((a, b) => (a.is_parent_servicetype && !b.is_parent_servicetype ? -1 : 1))
    }
  }, [servicesTypes])

  const create = useCreate('/visit-lists')
  const make = useCallback(
    item => {
      create({ servicetype_id: item.value })
    },
    [create]
  )

  if (dictsError) return <ErrorIcon />

  return (
    <MakerList
      dataSource={services}
      loading={dictsLoading}
      icon='schedule'
      renderItem={item => item.label}
      onItemClick={make}
    />
  )
}

export const NewsMaker = () => {
  const create = useCreate('/news')

  return (
    <MakerList
      renderItem={item => item.text}
      dataSource={[{ text: 'Создать новость', onClick: create }]}
    />
  )
}

export const BalanceMaker = () => {
  const navigate = useNavigate()
  return (
    <MakerList
      renderItem={item => item.text}
      dataSource={[
        {
          text: 'Баланс счетов',
          onClick: () => navigate('/accounts-balances'),
          extraIcon: 'dollar',
        },
        {
          text: 'Баланс клиентов',
          onClick: () => navigate('/clients-balances'),
          extraIcon: 'dollar',
        },
      ]}
    />
  )
}

export const PaymentsRecipientsMaker = () => {
  const {
    data: { accounts },
    dictsError,
    dictsLoading,
  } = useDictsForSelects()

  const create = useCreate('/payment-receipts')
  const make = useCallback(
    item => {
      create({ account_id: item.value })
    },
    [create]
  )

  if (dictsError) return <ErrorIcon />

  return (
    <MakerList
      loading={dictsLoading}
      renderItem={item => item.label}
      dataSource={accounts}
      onItemClick={make}
    />
  )
}

export const PaymentsMaker = () => {
  const {
    data: { paymentsTypes },
    dictsError,
    dictsLoading,
  } = useDictsForSelects()

  const create = useCreate('/payments')
  const make = useCallback(
    item => {
      create({ paymentstype_id: item.value })
    },
    [create]
  )

  if (dictsError) return <ErrorIcon />

  return (
    <MakerList
      loading={dictsLoading}
      renderItem={item => item.label}
      dataSource={paymentsTypes}
      onItemClick={make}
    />
  )
}

export const TransferMaker = () => {
  const {
    data: { accounts },
    dictsError,
    dictsLoading,
  } = useDictsForSelects()

  const create = useCreate('/transfers')
  const make = useCallback(
    item => {
      create({ account_id: item.value })
    },
    [create]
  )

  if (dictsError) return <ErrorIcon />

  return (
    <MakerList
      loading={dictsLoading}
      renderItem={item => item.label}
      dataSource={accounts}
      onItemClick={make}
    />
  )
}

export const SalaryMaker = () => {
  const {
    data: { salaryRecipients },
    dictsError,
    dictsLoading,
  } = useDictsForSelects()

  const create = useCreate('/salaries')
  const make = useCallback(
    item => {
      create({ recipient_id: item.value })
    },
    [create]
  )

  if (dictsError) return <ErrorIcon />

  return (
    <MakerList
      loading={dictsLoading}
      renderItem={item => item.label}
      dataSource={salaryRecipients}
      onItemClick={make}
    />
  )
}

export const DocsMaker = ({ navigate }) => {
  const createFreezing = useCreate('/freezings')
  const createAdjustments = useCreate('/manual-adjustments')

  return (
    <Collapse accordion className={s.makerCollapse}>
      <CollapsePanel header='Поступление оплаты на счет' key='payment-receipts'>
        <PaymentsRecipientsMaker />
      </CollapsePanel>

      <CollapsePanel header='Затрата по категории' key='payments'>
        <PaymentsMaker />
      </CollapsePanel>

      <CollapsePanel header='Перевод со счета' key='transfers'>
        <TransferMaker />
      </CollapsePanel>

      <CollapsePanel header='Зарплата сотруднику' key='salaries'>
        <SalaryMaker />
      </CollapsePanel>

      <CollapsePanel header='Лист посещения по услуге' key='visit-lists'>
        <VisitListMaker />
      </CollapsePanel>

      <CollapsePanel header='Клиентские документы' key='client'>
        <MakerList
          renderItem={item => item.text}
          dataSource={[
            { text: 'Заморозка', onClick: createFreezing },
            { text: 'Корректировка', onClick: createAdjustments },
          ]}
        />
      </CollapsePanel>
    </Collapse>
  )
}

const MakerList = ({ renderItem, dataSource, onItemClick, icon, bordered }) => {
  return (
    <List
      style={{ background: '#fff', color: 'var(--c-corporate)' }}
      size='small'
      bordered={bordered}
      dataSource={dataSource}
      renderItem={({ extraIcon = 'plus', onClick, ...item }) => (
        <List.Item
          onClick={() => (onClick ? onClick() : onItemClick(item))}
          extra={<Icon className={s.makerIcon} type={extraIcon} />}
        >
          <div className={s.makerText}>{renderItem(item)}</div>
        </List.Item>
      )}
    />
  )
}
