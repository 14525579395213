import React from 'react'
import VideoWidget from '../widgets/VideoWidget'

import { TitleFixed } from '../components/PageTemplate'

export default () => {
  return (
    <>
      <TitleFixed>Видеонаблюдение</TitleFixed>
      <VideoWidget open={true}/>
    </>
  )
}
