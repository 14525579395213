import React from 'react'
import { useGetVisitListPosts } from '../../../api/clientBalances'
import { Table } from '../../../components/Inputs'

const columns = [
  {
    title: 'Клиент',
    dataIndex: 'user.name',
    key: 'user.name',
  },
  {
    title: 'Ребенок',
    dataIndex: 'clientChildren.name',
    key: 'clientChildren.name',
  },
  {
    title: 'Пояснение',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'руб',
    dataIndex: 'sum',
    key: 'sum',
    align: 'right',
  },
]

const VisitListPosts = ({ id }) => {
  const clientsAccountsMovements = useGetVisitListPosts(id)

  return (
    <>
      <Table
        dataSource={clientsAccountsMovements}
        rowKey={record => record.owner_id + (record.clientChildren ? record.clientChildren.id : '')}
        columns={columns}
      />
    </>
  )
}

export default VisitListPosts
