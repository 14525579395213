import React, { useCallback, useMemo } from 'react'
import { TableSearchDropdownProps, Table } from '../../components/Inputs'
import { useGetList } from '../../api/clientBalances'
import useFilters from '../../core/useFilter'
import { ErrorIcon } from '../../components/Icons'
import { Icon } from 'antd'

const ClientsBalances = ({ navigate, titleRender }) => {
  // Data
  const { data, loading, error, refetch } = useGetList()

  // Filters and Columns
  const { filters, setFilters } = useFilters('ClientsAccountsMovement')

  const columns = useMemo(
    () => [
      {
        ...TableSearchDropdownProps('user.name'),
        title: 'Клиент',
        dataIndex: 'user.name',
        key: 'user.name',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => (a.user.name && b.user.name ? a.user.name.localeCompare(b.user.name) : 0),
      },
      {
        title: 'Баланс',
        dataIndex: 'sum',
        key: 'sum',
        align: 'right',
        onFilter: (value, record) => (value ? record.sum >= 0 : record.sum < 0),
        filters: [
          { text: 'Положительный', value: true },
          { text: 'Отрицательный', value: false },
        ],
        render: val => (
          <span
            style={{
              color: val > 0 ? 'var(--c-positive)' : 'var(--c-danger)',
            }}
          >
            {val}
          </span>
        ),
      },
      {
        title: <Icon type='user' />,
        dataIndex: 'user.is_active',
        key: 'user.is_active',
        render: val => (val ? null : <Icon type='close' />),
        onFilter: (value, record) => record.user.is_active === value,
        filters: [
          { text: 'Активный', value: true },
          { text: 'Не активный', value: false },
        ],
        defaultFilteredValue: [true],
        width: 20,
      },
    ],
    []
  )

  // Callbacks
  const update = useCallback(() => refetch(), [refetch])
  const rowKey = useCallback(record => record.user.id, [])
  const onRow = useCallback(
    record => ({
      onClick: e => {
        setFilters({ ...filters, userId: record.user.id })
        navigate(`${record.user.id}`)
      },
    }),
    [navigate, filters, setFilters]
  )

  if (error) return <ErrorIcon />

  return (
    <>
      {titleRender({ update, loading, showBackBtn: false, navigate })}

      <Table loading={loading} dataSource={data} rowKey={rowKey} columns={columns} onRow={onRow} />
    </>
  )
}

export default ClientsBalances
