import React, { Fragment } from 'react'
import Fieldset from 'react-fieldset'
import { FieldArray } from './FormikHocs'
import { Button, Card } from 'antd'

import './EasyTable.css'

export const EasyArray = ({
  name,
  children,
  keyProp,
  deleteBtn = true,
  addBtnContent = '+',
  deleteBtnContent = '',
  ...props
}) => (
  <FieldArray
    {...props}
    name={name}
    render={({ array, push, remove }) => (
      <Fragment>
        {array && array.map
          ? array.map((item, index) => (
              <Card
                style={{ marginBottom: 30 }}
                key={typeof keyProp === 'function' ? keyProp(item) : item[keyProp]}
              >
                <Fieldset name={`${name || ''}[${index}]`}>{children}</Fieldset>
                {deleteBtn ? (
                  <div className='easy-array-delete-container' style={{ textAlign: 'right' }}>
                    <Button icon='delete' type='danger' onClick={() => remove(index)}>
                      {deleteBtnContent}
                    </Button>
                  </div>
                ) : null}
              </Card>
            ))
          : null}
        <div style={{ textAlign: 'center' }}>
          <Button icon='plus' type='primary' onClick={push}>
            {addBtnContent}
          </Button>
        </div>
      </Fragment>
    )}
  />
)

//1. new row function to make all inputs controlled
//		if take it from inside onAdd, it always need to give onAdd from parent - dont want it!!!
//		not take it like a object in props - it will be not a new object, just a link
//		propbably in need to be a function || Promise

//add all Fieldset to row with value='', to make inputs controlled
// emptyValuesItem = () => (
// 	this.props.children.reduce( (newItem, field)=>{
// 		newItem[field.props.name]=''
// 		return newItem
// 	}, {} )
// )

//FormikTable
export const EasyTable = ({
  children,
  name,
  willPush,
  willRemove,
  notificationFunction,
  readOnly,

  columns,

  defaultTableClassName,
  className,
  tableActionsContainerClassName,
  enableAdd,
  addBtnClassName,
  addBtnTitle,
  addBtnContent,
  enableDelete,
  deleteBtnClassName,
  deleteBtnTitle,
  deleteBtnContent,
}) => {
  return (
    <FieldArray
      name={name}
      willPush={willPush}
      willRemove={willRemove}
      notificationFunction={notificationFunction}
      render={({ array, push, remove, ...props }) => (
        <Fragment>
          <table className={defaultTableClassName + ' ' + (className ? className : '')}>
            <thead>
              <tr>
                {columns.map((item, index) => (
                  <th key={index}>{item}</th>
                ))}
                <th>
                  <FieldArrayBtn
                    icon='plus'
                    enable={enableAdd}
                    className={addBtnClassName}
                    title={addBtnTitle}
                    onClick={() => {
                      push({})
                    }}
                    content={addBtnContent}
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              {array.map((item, index) => (
                <tr key={index}>
                  <Fieldset name={`${name}[${index}]`} readOnly={readOnly}>
                    {children.map((child, index) => (
                      <td key={index}>{child}</td>
                    ))}
                  </Fieldset>

                  {enableDelete ? (
                    <td className='actions'>
                      <FieldArrayBtn
                        icon='delete'
                        onClick={() => remove(index)}
                        enable={enableDelete}
                        className={deleteBtnClassName}
                        title={deleteBtnTitle}
                        content={deleteBtnContent}
                      />
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </Fragment>
      )}
    />
  )
}

EasyTable.defaultProps = {
  defaultTableClassName: 'easy-table',
  tableActionsContainerClassName: 'table-actions',
  wrapCells: true,

  enableAdd: true,
  addBtnContent: '',
  addBtnClassName: '',
  addBtnTitle: 'Add row',

  enableDelete: true,
  deleteBtnContent: '',
  deleteBtnClassName: '',
  deleteBtnTitle: 'Delete row',
}

// export const Table=EasyTable;
// Table.defaultProps={
// 	defaultTableClassName: 'isl-table editable',
// 	tableActionsContainerClassName: 'table-header-actions',
// 	wrapCells: true,

// 	enableAdd: true,
// 	addBtnContent: <i className="fa fa-plus"></i>,
// 	addBtnClassName: 'btn',
// 	addBtnTitle: 'Добавить строку',

// 	enableDelete: true,
// 	deleteBtnContent: <i className="fa fa-trash"></i>,
// 	deleteBtnTitle: 'Удалить',
// 	deleteBtnClassName: 'btn table-action-btn',
// }

const FieldArrayBtn = ({ enable, content, ...props }) => {
  return enable ? (
    <Button ghost {...props}>
      {content}
    </Button>
  ) : null
}

//FormikTable
export const EasyTableEmu = ({
  children,
  name,
  willPush,
  willRemove,
  notificationFunction,
  readOnly,
  keyProp,

  columns,

  defaultTableClassName,
  className,
  tableActionsContainerClassName,
  enableAdd = true,
  addBtnClassName,
  addBtnTitle = 'Добавить',
  addBtnContent,
  enableDelete = true,
  deleteBtnClassName,
  deleteBtnTitle = 'Удалить',
  deleteBtnContent,
  ...props
}) => {
  return (
    <FieldArray
      name={name}
      willPush={willPush}
      willRemove={willRemove}
      notificationFunction={notificationFunction}
      render={({ array, push, remove }) => (
        <Fragment>
          <div {...props} className='table-emu-container table-emu-header'>
            {columns.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
            <FieldArrayBtn
              icon='plus'
              enable={enableAdd}
              type='primary'
              className={addBtnClassName}
              title={addBtnTitle}
              onClick={() => {
                push({})
              }}
              content={addBtnContent}
            />
          </div>
          <div {...props} className='table-emu-container'>
            {array.map((item, index) => (
              <Fragment key={typeof keyProp === 'function' ? keyProp(item) : item[keyProp]}>
                <Fieldset name={`${name}[${index}]`} readOnly={readOnly}>
                  {children.map((child, index) => (
                    <div key={index}>{child}</div>
                  ))}
                </Fieldset>

                {enableDelete ? (
                  <FieldArrayBtn
                    icon='delete'
                    onClick={() => remove(index)}
                    enable={enableDelete}
                    type='danger'
                    className='no-border'
                    title={deleteBtnTitle}
                    content={deleteBtnContent}
                  />
                ) : null}
              </Fragment>
            ))}
          </div>
        </Fragment>
      )}
    />
  )
}
