import React from 'react'

export const SHORT_DATE_FORMAT = 'DD.MM'
export const LONG_DATE_FORMAT = 'DD.MM.YY'
// TODO make it HOOK (with useMobile)
const IS_MOBILE = window.innerWidth <= 768
export const DEFAULT_DATE_FORMAT = IS_MOBILE ? SHORT_DATE_FORMAT : LONG_DATE_FORMAT

export const ROLES = {
  admin: 'ADMIN',
  employee: 'EMPLOYEE',
  client: 'CLIENT',
}

export const ROLES_IDS = {
  admin: '0a0310ca-2ca3-48be-bfde-6348d803362c',
  employee: '99e8fcc3-50bd-4f07-870c-141a7b2ad052',
  client: '330d7131-0192-4da0-a68b-bfa2306e062e',
}

// VISIT_LIST_POSTS_DESCRIPTION_TYPES
export const VISIT_LIST_POSTS_DESCRIPTION_TYPES = [
  'Посещение',
  'Адаптация',
  'Заморозка',
  'Отсутствовал',
  'Пропуск',
]

export const docsRoutes = [
  {
    to: '/payment-receipts',
    text: 'Поступление оплаты',
    icon: 'plus-circle',
    tableName: 'Doc_PaymentReceipts',
  },
  {
    to: '/payments',
    text: 'Затраты',
    icon: 'minus-circle',
    tableName: 'Doc_Payments',
  },
  {
    to: '/salaries',
    text: 'Зарплата',
    icon: 'contacts',
    tableName: 'Doc_Salaries',
  },
  {
    to: '/transfers',
    text: 'Перевод',
    icon: 'swap',
    tableName: 'Doc_Transfers',
  },
  {
    to: '/freezings',
    text: 'Заморозки',
    icon: 'pause-circle',
    tableName: 'Doc_Freezing',
  },
  {
    to: '/manual-adjustments',
    text: 'Корректировка',
    icon: 'form',
    tableName: 'Doc_ManualAdjustments',
  },
  {
    to: '/visit-lists',
    text: 'Листы посещений',
    icon: 'schedule',
    tableName: 'Doc_VisitList',
  },
]

export const makeDocLink = (row, isAdmin) => {
  const docRoute = docsRoutes.find(i => i.tableName === row.owner_table_name)
  if (!docRoute) {
    console.log('Udefined route to doc: ' + row.owner_table_name)
    return null
  }

  const docLink = isAdmin ? (
    <a href={docRoute.to + '/' + row.owner_id} target='_blank' rel='noopener noreferrer'>
      {docRoute.text}
    </a>
  ) : (
    <span>{docRoute.text}</span>
  )

  return docLink
}
