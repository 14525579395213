import React, { useEffect, useState } from 'react'
import Iframe from 'react-iframe'

import { Collapse, CollapsePanel } from '../components/Inputs'
import { LoaderProgress, ErrorIcon } from '../components/Icons'

import { useGetList } from '../api/cameras'

const VideoWidget = ({ open }) => {
  const { data, error, loading } = useGetList()
  const [parentWidth, setParentWidth] = useState(320)

  useEffect(() => {
    setParentWidth(document.getElementById('page-content').clientWidth)
  }, [])

  if (loading) return <LoaderProgress />
  if (error) return <ErrorIcon />

  return (
    <Collapse defaultActiveKey={open && [0]} accordion className='maker-collapse'>
      {data.map((c, index) => (
        <CollapsePanel header={c.description} key={index} style={{ padding: 0 }}>
          <Iframe
            className='video'
            url={'https:' + c.src}
            width='100%'
            height={parentWidth * 0.57 + 'px'}
            frameBorder='0'
            position='relative'
            allowFullScreen
          />
        </CollapsePanel>
      ))}
    </Collapse>
  )
}

export default VideoWidget
