import React, { useCallback, useMemo } from 'react'
import moment from 'moment'

import { TitleFixed } from '../../../components/PageTemplate'
import {
  Table,
  Button,
  ButtonGroup,
  Icon,
  DatesFilterDropdownProps,
  TableSearchDropdownProps,
} from '../../../components/Inputs'
import { DEFAULT_DATE_FORMAT } from '../../../core/const'

import { ErrorIcon } from '../../../components/Icons'
import useFilters from '../../../core/useFilter'
import { useGetList } from '../../../api/freezing'

const Freezings = ({ navigate }) => {
  // Data
  const { data, loading, error, refetch } = useGetList()

  // Callbacks
  const create = useCallback(() => navigate('/freezings/new'), [navigate])
  const update = useCallback(() => refetch(), [refetch])
  const edit = useCallback(row => navigate('/freezings/' + row.id), [navigate])

  // Filters and Columns
  const { filters, setFilters } = useFilters('Freezings')
  const columns = useMemo(
    () => [
      {
        ...DatesFilterDropdownProps(),
        filteredValue: filters['date'],
        title: 'Период',
        render: (val, record) =>
          'C ' +
          moment(record.date_start).format(DEFAULT_DATE_FORMAT) +
          ' по ' +
          moment(record.date_end).format(DEFAULT_DATE_FORMAT),
      },
      {
        ...TableSearchDropdownProps('child.name'),
        title: 'Ребенок',
        dataIndex: 'child.name',
        key: 'child.name',
        filteredValue: filters['child.name'],
      },
      {
        filters: [
          { value: true, text: 'Адаптация' },
          { value: false, text: 'Заморозка' },
        ],
        title: 'Адаптация',
        dataIndex: 'adaptation',
        key: 'adaptation',
        render: val => (val ? <Icon type='check' /> : ''),
        onFilter: (value, record) => record.adaptation === value,
        filteredValue: filters['adaptation'],
      },
    ],
    [filters]
  )

  if (error) return <ErrorIcon />

  return (
    <>
      <TitleFixed
        text='Заморозки'
        commands={
          <ButtonGroup>
            <Button loading={loading} icon='sync' onClick={update}>
              Обновить
            </Button>
            <Button type='primary' icon='plus' onClick={create}>
              Создать
            </Button>
          </ButtonGroup>
        }
      />

      <Table
        loading={loading}
        dataSource={data}
        rowKey='id'
        onChange={(pagination, filters, sorter) => {
          setFilters(filters)
        }}
        columns={columns}
        onRow={record => ({ onClick: e => edit(record) })}
      />
    </>
  )
}

export default Freezings
