import React, { useCallback, useMemo } from 'react'

import { TitleFixed } from '../../../components/PageTemplate'

import {
  Table,
  Button,
  ButtonGroup,
  DatesFilterDropdownProps,
  TableSearchDropdownProps,
} from '../../../components/Inputs'

import { ErrorIcon } from '../../../components/Icons'
import useFilters from '../../../core/useFilter'
import { useProfile } from '../../../api/profile'
import { useGetList } from '../../../api/visitLists'
import { useDictsForFilters } from '../../../api/dicts'

const VisitLists = ({ navigate }) => {
  const { IS_ADMIN } = useProfile()

  // Data
  const { data, loading, error, refetch } = useGetList()

  // Callbacks
  const create = useCallback(() => navigate('/visit-lists/new'), [navigate])
  const update = useCallback(() => refetch(), [refetch])
  const edit = useCallback(row => navigate('/visit-lists/' + row.id), [navigate])

  // Dicts
  const {
    data: { servicesTypes, structure },
    dictsError,
  } = useDictsForFilters()

  const parsedServicesTypes = useMemo(
    () =>
      servicesTypes &&
      servicesTypes
        .filter(i => !i.parent_servicetype_id)
        .sort((a, b) => (a.is_parent_servicetype && !b.is_parent_servicetype ? -1 : 1)),
    [servicesTypes]
  )

  // Filters and Columns
  const { filters, setFilters } = useFilters('VisitLists')
  const columns = useMemo(
    () =>
      [
        {
          ...DatesFilterDropdownProps(),
          filteredValue: filters['date'],
        },
        {
          title: 'Услуга',
          dataIndex: 'servicetype.name',
          key: 'servicetype.name',
          filters: parsedServicesTypes,
          onFilter: (value, record) => record.servicetype.id === value,
          filteredValue: filters['servicetype.name'],
        },

        {
          filters: structure,
          title: 'Садик',
          dataIndex: 'structure.name',
          key: 'structure.name',
          onFilter: (value, record) => record.structure.id === value,
          filteredValue: filters['structure.name'],
        },

        IS_ADMIN
          ? {
              ...TableSearchDropdownProps('user.name'),
              title: 'Автор',
              dataIndex: 'user.name',
              key: 'user.name',
              filteredValue: filters['user.name'],
            }
          : false,
        {
          title: 'Детей',
          dataIndex: 'childrenCount',
          key: 'childrenCount',
          align: 'right',
        },
      ].filter(i => i),
    [IS_ADMIN, filters, structure, parsedServicesTypes]
  )

  if (error || dictsError) return <ErrorIcon />

  return (
    <>
      <TitleFixed
        text='Журналы'
        commands={
          <ButtonGroup>
            <Button loading={loading} icon='sync' onClick={update}>
              Обновить
            </Button>
            <Button type='primary' icon='plus' onClick={create}>
              Создать
            </Button>
          </ButtonGroup>
        }
      />

      <Table
        loading={loading}
        dataSource={data}
        rowKey='id'
        onChange={(pagination, filters, sorter) => {
          setFilters(filters)
        }}
        onRow={record => ({ onClick: e => edit(record) })}
        columns={columns}
      />
    </>
  )
}

export default VisitLists
