import React, { useCallback, useEffect } from 'react'
import { Router } from '@reach/router'

import { ClientsBalancesFilters } from './ClientsBalancesFilters'
import ClientsBalances from './ClientsBalances'
import ClientsHistory from './ClientsHistory'
import { Statistic } from 'antd'
import { TitleFixed } from '../../components/PageTemplate'
import { Button, ButtonGroup, ReportFilter } from '../../components/Inputs'
import useFilters from '../../core/useFilter'

const ClientsBalanceAdminReport = () => {
  const { setDefaultFilter } = useFilters('ClientsAccountsMovement')
  //ставим дефолтное значение фильтров
  useEffect(() => setDefaultFilter, [])

  const balanceRender = useCallback(({ balance }) => (
    <ReportFilter label='Баланс'>
      <Statistic
        value={balance}
        suffix='₽'
        valueStyle={{
          fontSize: 24,
          lineHeight: 1,
          color: balance > 0 ? 'var(--c-positive)' : 'var(--c-danger)',
        }}
      />
    </ReportFilter>
  ))

  const titleRender = useCallback(
    ({ update, loading, navigate, showBackBtn, backButtonOnClick }) => (
      <>
        <TitleFixed
          text='Баланс клиентов'
          withBackBtn={!!showBackBtn}
          backButtonOnClick={backButtonOnClick}
          commands={
            <ButtonGroup>
              <Button loading={loading} icon='sync' onClick={update}>
                Обновить
              </Button>
            </ButtonGroup>
          }
        />
        <ClientsBalancesFilters navigate={navigate} />
      </>
    )
  )

  return (
    <Router primary={false}>
      <ClientsBalances path='/' titleRender={titleRender} balanceRender={balanceRender} />

      <ClientsHistory
        path='/:id'
        showDocumentLink={true}
        titleRender={titleRender}
        balanceRender={balanceRender}
      />
    </Router>
  )
}

export default ClientsBalanceAdminReport
