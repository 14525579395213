import React, { useCallback, useMemo } from 'react'
import { TitleFixed } from '../../..//components/PageTemplate'
import { Table, Button, ButtonGroup, DatesFilterDropdownProps } from '../../../components/Inputs'
import { groupingColumn, useDateReportFilter, useGrouping } from '../../../components/ReportHocs'
import { useDictsForFilters } from '../../../api/dicts'
import { useGetReport } from '../../../api/payments'
import { ErrorIcon } from '../../../components/Icons'

const PaymentsReport = () => {
  // Groups and Filters
  const [grouping, toggleGrouping] = useGrouping(['account_id'])
  const [filters, setFilters] = useDateReportFilter()

  const { data, loading, error, refetch } = useGetReport({ filters, grouping })

  // Callbacks
  const update = useCallback(() => refetch(), [refetch])

  // Dicts
  const {
    data: { accounts, paymentsTypes },
    dictsError,
  } = useDictsForFilters()

  const columns = useMemo(() => {
    return [
      {
        ...DatesFilterDropdownProps(setFilters),
        filteredValue: filters,
      },
      {
        ...groupingColumn({
          title: 'Счет списания',
          key: 'account_id',
          grouping,
          toggleGrouping,
          onFilter: (value, record) => record.account.id === value,
        }),
        filters: accounts,
        dataIndex: 'account.name',
        key: 'account.name',
      },
      {
        ...groupingColumn({
          title: 'Категория',
          key: 'paymentstype_id',
          grouping,
          toggleGrouping,
          onFilter: (value, record) => record.paymentsType.id === value,
        }),
        filters: paymentsTypes,
        dataIndex: 'paymentsType.name',
        key: 'paymentsType.name',
      },
      {
        title: 'Сумма',
        dataIndex: 'sum',
        key: 'sum',
      },
    ]
  }, [paymentsTypes, accounts, grouping, filters, setFilters, toggleGrouping])

  if (error || dictsError) return <ErrorIcon />

  return (
    <>
      <TitleFixed
        text='Отчет по затратам'
        commands={
          <ButtonGroup>
            <Button loading={loading} icon='sync' onClick={update}>
              Обновить
            </Button>
          </ButtonGroup>
        }
      />

      <Table
        loading={loading}
        dataSource={data}
        rowKey={record =>
          `${record.account && record.account.id}${record.paymentsType && record.paymentsType.name}`
        }
        columns={columns}
      />
    </>
  )
}

export default PaymentsReport
