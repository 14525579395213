import { useEffect, useState } from 'react'

const useOrientation = (defaultValue = 'portrait') => {
  const [orientation, setOrientation] = useState(defaultValue)

  useEffect(() => {
    detectOrientation()
    window.addEventListener('orientationchange', detectOrientation)
    return () => window.removeEventListener('orientationchange', detectOrientation)
  })

  const detectOrientation = () => {
    setTimeout(() => {
      const portrait = window.innerHeight > window.innerWidth
      setOrientation(portrait ? 'portrait' : 'landscape')
    }, 100)
  }

  return orientation
}

export default useOrientation
