import React, { useState, useCallback } from 'react'
import share from '../assets/img/share.png'
import homescreen from '../assets/img/homescreen.png'
import { isPwa, iosDevice } from '../core/PWAConts'

export default props => {
  // Detects if device is on iOS
  const userAgent = window.navigator.userAgent.toLowerCase()
  const iphone = /iphone/.test(userAgent)
  const [hider, setHider] = useState()

  const hidden = sessionStorage.getItem('hidePWAInvitation')
  const hide = useCallback(() => {
    sessionStorage.setItem('hidePWAInvitation', true)
    setHider(true)
  }, [])

  // Checks if should display install popup notification:
  if (!iosDevice || isPwa || hidden || hider) {
    return null
  }

  return (
    <div
      onClick={hide}
      style={{
        background: '#f5f5f5',
        height: '80px',
        position: 'fixed',
        zIndex: 900,
        textAlign: 'center',
        padding: '8px 0',

        width: iphone ? '100%' : '350px',
        bottom: iphone ? 70 : 'auto',
        top: iphone ? 'auto' : 10,
        left: iphone ? 0 : 'auto',
        right: iphone ? 'auto' : 20,
      }}
    >
      <span style={{ color: 'var(--c-corporate)' }}>
        Установите это приложение на свой {iosDevice}:
      </span>{' '}
      <br />
      откройте эту страничку в Safari, нажмите{' '}
      <img
        src={share}
        alt='share-icon'
        style={{ height: 20, marginTop: -5, display: 'inline-block' }}
      />{' '}
      <br />
      и затем <img src={homescreen} alt='home-icon' style={{ height: 20 }} /> На экран "Домой"
    </div>
  )
}
