import React, { useEffect } from 'react'
import { Router } from '@reach/router'
import useFilters from '../../core/useFilter'
import AccountsBalances from './AccountsBalances'
import AccountsHistory from './AccountsHistory'

const AccountsReport = () => {
  const { setDefaultFilter } = useFilters('AccountsMovement')

  //ставим дефолтное значение фильтров
  useEffect(() => setDefaultFilter, [])

  return (
    <Router primary={false}>
      <AccountsBalances path='/' />

      <AccountsHistory path='/:id' />
    </Router>
  )
}

export default AccountsReport
