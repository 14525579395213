import React, { useCallback, useMemo } from 'react'

import { TitleFixed } from '../../../components/PageTemplate'
import {
  Table,
  Button,
  ButtonGroup,
  DatesFilterDropdownProps,
  TableSearchDropdownProps,
} from '../../../components/Inputs'

import { ErrorIcon } from '../../../components/Icons'
import useFilters from '../../../core/useFilter'
import { useGetList } from '../../../api/manualAdjustments'

const ManualAdjustments = ({ navigate }) => {
  // Data
  const { data, loading, error, refetch } = useGetList()

  // Callbacks
  const create = useCallback(() => navigate('/manual-adjustments/new'), [navigate])
  const update = useCallback(() => refetch(), [refetch])
  const edit = useCallback(row => navigate('/manual-adjustments/' + row.id), [navigate])

  // Filters and Columns
  const { filters, setFilters } = useFilters('ManualAdjustments')
  const columns = useMemo(
    () => [
      {
        ...DatesFilterDropdownProps(),
        filteredValue: filters['date'],
      },
      {
        ...TableSearchDropdownProps('edit_user.name'),
        title: 'Клиент',
        dataIndex: 'edit_user.name',
        key: 'edit_user.name',
        filteredValue: filters['edit_user.name'],
      },
      {
        title: 'Сумма',
        dataIndex: 'sum',
        key: 'sum',
      },
    ],
    [filters]
  )

  if (error) return <ErrorIcon />

  return (
    <>
      <TitleFixed
        text='Корректировки'
        commands={
          <ButtonGroup>
            <Button loading={loading} icon='sync' onClick={update}>
              Обновить
            </Button>
            <Button type='primary' icon='plus' onClick={create}>
              Создать
            </Button>
          </ButtonGroup>
        }
      />

      <Table
        loading={loading}
        dataSource={data}
        rowKey='id'
        onChange={(pagination, filters, sorter) => {
          setFilters(filters)
        }}
        onRow={record => ({ onClick: e => edit(record) })}
        columns={columns}
      />
    </>
  )
}

export default ManualAdjustments
