import React, { useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { useNavigate } from '@reach/router'

import { LoaderProgress } from '../../components/Icons'
import { setCurrentUser } from '../../cache'

const LogOut = () => {
  const client = useApolloClient()
  const navigate = useNavigate()

  useEffect(() => {
    const logout = async () => {
      await client.resetStore()
      localStorage.removeItem('token')
      setCurrentUser({ IS_AUTHORIZED: false })
      navigate('/', { replace: true })
    }
    logout()
  }, [])

  return <LoaderProgress />
}

export default LogOut
