import { gql } from '@apollo/client'
import { useQueryExt } from '../core/apolloHooks'
import useFilters from '../core/useFilter'
import { useMemo } from 'react'
import { round } from 'lodash'

export const FRAGMENT = gql`
  fragment accountsMovement on AccountsMovement {
    sum
    account {
      id
      name
    }
  }
`

//Group by accounts
export const GET_LIST = gql`
  query AccountsMovementsList($filters: Filter) {
    balance: accountsMovements(
      grouping: ["account_id"]
      orderBy: { column: "account_id" }
      filters: $filters
    ) {
      ...accountsMovement
    }
  }
  ${FRAGMENT}
`

const useRefetchVariables = () => {
  const { filters } = useFilters('AccountsMovement')

  return {
    filters: {
      date: { operator: '<', value: filters.date },
    },
  }
}

export const useGetList = () => {
  const variables = useRefetchVariables()
  const queryResult = useQueryExt(GET_LIST, {
    variables,
    notifyOnNetworkStatusChange: true,
    listPath: 'balance',
  })

  const data = useMemo(() => {
    const { data } = queryResult
    return data && data.length ? data.map(b => ({ ...b, sum: round(b.sum, 2) })) : []
  }, [queryResult.data])

  return { ...queryResult, data }
}

//History
export const GET_ACCOUNT_HISTORY = gql`
  query($filters: Filter) {
    accountsMovements(filters: $filters, orderBy: { column: "date", sort: "desc" }) {
      sum
      date
      owner_table_name
      owner_id
      description
    }
  }
`
const useRefetchHistoryVariables = () => {
  const { filters } = useFilters('AccountsMovement')
  const accountFilter = filters.accountId ? { account_id: filters.accountId } : {}

  return {
    filters: {
      date: [
        { operator: '<', value: filters.date },
        { operator: '>=', value: filters.dateFromHistory },
      ],
      ...accountFilter,
    },
  }
}

export const useGetAccountHistory = () => {
  const variables = useRefetchHistoryVariables()

  const queryResult = useQueryExt(GET_ACCOUNT_HISTORY, {
    variables,
    notifyOnNetworkStatusChange: true,
    listPath: 'accountsMovements',
  })

  const data = useMemo(() => {
    const { data } = queryResult
    return data && data.length ? data.map(b => ({ ...b, sum: round(b.sum, 2) })) : []
  }, [queryResult.data])

  return { ...queryResult, data }
}
