import React from 'react'
import { Formik, Form } from 'formik'
import { Link } from '@reach/router'
import { Icon } from 'antd'

import { FormItem, Input, Password, Button } from '../components/Inputs'
import { TitleFixed } from '../components/PageTemplate'
import { useProfile, useSave } from '../api/profile'

const Profile = () => {
  const { currentUser } = useProfile()
  const [save, { loading }] = useSave()

  return (
    <Formik initialValues={currentUser} onSubmit={save}>
      {formikProps => (
        <Form>
          <TitleFixed
            commands={
              <Button loading={loading} type='primary' icon='save' htmlType='submit'>
                Сохранить
              </Button>
            }
            text='Профиль'
          />

          <FormItem
            label='Имя'
            component={Input}
            name='name'
            placeholder='Имя ребенка или пользователя'
          />

          <FormItem label='Логин' component={Input} name='login' placeholder='Ваш логин' />

          <FormItem label='Телефон' component={Input} name='tel' placeholder='Телефон для связи' />

          <FormItem
            label='Пароль'
            component={Password}
            name='password'
            placeholder='Новый пароль'
          />

          <Button
            className='hidden-web mobile-fullwidth-button'
            style={{ marginTop: '15px' }}
            loading={loading}
            type='primary'
            icon='save'
            htmlType='submit'
          >
            Сохранить
          </Button>
          <Link
            className='hidden-web'
            to='/logout'
            style={{
              textAlign: 'center',
              width: '100%',
              display: 'block',
              background: '#fff',
              padding: '8px 0',
              color: '#f5222d',
              marginTop: '1px',
            }}
          >
            <Icon type='logout'></Icon>
            <span style={{ marginLeft: '10px' }}>Выйти</span>
          </Link>
        </Form>
      )}
    </Formik>
  )
}

export default Profile
