import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import client from './apollo'
import Pages from './pages'

// TODO: make it modules
import './assets/css/theme.css'
import './assets/css/responsive.css'

// TODO!!! remove with moment
import 'moment/locale/ru'

ReactDOM.render(
  <ApolloProvider client={client}>
    <Pages />
  </ApolloProvider>,
  document.getElementById('root')
)

serviceWorkerRegistration.register()