import { useCallback } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { useQueryExt, useMutationExt } from '../core/apolloHooks'
import get from 'lodash.get'
import { setCurrentUser } from '../cache'
import { ROLES } from '../core/const'
import { notify } from '../components/Inputs'

// Init store
export const initialProfile = {
  currentUser: null,
  IS_AUTHORIZED: false,
  IS_ADMIN: false,
  IS_MASTER_ADMIN: false,
  IS_CLIENT: false,
  IS_EMPLOYEE: false,
}
setCurrentUser(initialProfile)

const writeProfileInStore = currentUser => {
  if (currentUser) {
    setCurrentUser({
      currentUser,
      IS_AUTHORIZED: true,
      IS_ADMIN: currentUser.role.name === ROLES.admin,
      IS_MASTER_ADMIN: currentUser.role.name === ROLES.admin && currentUser.structure?.findIndex(str => str.level === 0) > -1,
      IS_CLIENT: currentUser.role.name === ROLES.client,
      IS_EMPLOYEE: currentUser.role.name === ROLES.employee,
    })
  }
}

export const FRAGMENT = `fragment profile on User {
	id
	name
	login
	is_active
	tel
	role{
    id
		name
	}
	structure{
		id
    name
    level
	}
	clientChildren {
		id
	}
}`

export const GET_PROFILE = gql`
  query {
    profile {
      currentUser {
        ...profile
      }
      structureIds
      parentStructureIds
    }
  }
  ${FRAGMENT}
`

export const useGetProfile = () => {
  const [getProfile, { loading, called, error }] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data && data.profile) {
        const { currentUser, structureIds, parentStructureIds } = data.profile
        writeProfileInStore({ ...currentUser, structureIds, parentStructureIds })
      }
    },
    onError: error => {
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token')
        window.location.reload()
      } else {
        notify(error)
      }
    },
  })

  return [getProfile, { loading, called, error }]
}

export const SAVE = gql`
  mutation PutUser($data: UserInput!) {
    user(user: $data) {
      ...profile
    }
  }
  ${FRAGMENT}
`

export const useSave = () => {
  const [mutate, result] = useMutationExt(SAVE)

  const save = useCallback(
    async ({ role, structure, structureIds, parentStructureIds, ...values }) => {

      const data = await mutate({ variables: { data: values } })
      const currentUser = get(data, 'user')
      writeProfileInStore(currentUser)
    },
    [mutate]
  )

  return [save, result]
}

// COMMON HOOK
export const USE_PROFILE = gql`
  query UseProfile {
    currentUser @client
  }
`

export const useProfile = () => {
  const { data } = useQueryExt(USE_PROFILE)
  return data.currentUser
}
