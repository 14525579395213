import React, { Component, PureComponent, createElement } from 'react'
import { Field as FromikField, FieldArray as FormikFieldArray, getIn } from 'formik'
import { withFieldset, withFullName } from 'react-fieldset'

const isSyntheticEvent = event => {
  return event && event.hasOwnProperty('bubbles') && event.hasOwnProperty('nativeEvent')
}

export const Field = withFieldset(FromikField)

export const formikInput = InnerComponent => {
  return class FormikInput extends Component {
    onChange = newValueOrEvent => {
      const isEvent = isSyntheticEvent(newValueOrEvent)

      //Fired default formik change handler
      if (isEvent) {
        this.props.form.handleChange(newValueOrEvent)
      } else {
        this.props.form.setFieldValue(this.props.field.name, newValueOrEvent)
      }

      if (this.props.onChange) {
        this.props.onChange(newValueOrEvent, this.props.form)
      }
    }

    onBlur = () => {
      this.props.form.handleBlur(this.props.field.name)
    }

    render() {
      let { field, form, calcProps, ...props } = this.props
      const calulatedProps = calcProps?.(field, form)
      let hasError = getIn(form.errors, field.name) && getIn(form.touched, field.name)
      let error = hasError ? getIn(form.errors, field.name) : ''
      props = {
        ...props,
        ...field,
        ...calulatedProps,
        hasError,
        error,
        onChange: this.onChange,
        onBlur: this.onBlur,
      }

      return <InnerComponent {...props} />
    }
  }
}

export const withEventFieldArray = InnerComponent =>
  class WithEventFieldArray extends PureComponent {
    push = async function (arrayHelpers) {
      let newRow = {}
      if (this.props.willPush) {
        try {
          newRow = await this.props.willPush()
        } catch (err) {
          return false
        }
      }
      arrayHelpers.push(newRow)
    }

    remove = async function (index, arrayHelpers) {
      if (this.props.willRemove) {
        try {
          let item = getIn(arrayHelpers.form.values, `${this.props.name}[${index}]`)
          await this.props.willRemove(item, index)
        } catch (err) {
          return false
        }
      }

      return arrayHelpers.remove(index)
    }

    render() {
      let { children, render, component, name, ...props } = this.props
      children = render || children

      return (
        <InnerComponent
          name={name}
          render={arrayHelpers => {
            let richArrayHelpers = {
              ...arrayHelpers,
              push: () => {
                this.push(arrayHelpers)
              },
              remove: index => {
                this.remove(index, arrayHelpers)
              },
              array: getIn(arrayHelpers.form.values, name) || [],
            }

            //Formik renders
            if (typeof children === 'function') {
              return children(richArrayHelpers)
            } else {
              return createElement(
                component,
                { ...props, arrayHelpers: richArrayHelpers },
                children,
              )
            }
          }}
        />
      )
    }
  }

export const FieldArray = withFullName(withEventFieldArray(FormikFieldArray))
