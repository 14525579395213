import React, { useMemo, useCallback } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { TitleFixed } from '../../../components/PageTemplate'
import {
  Input,
  FormItem,
  Button,
  ButtonGroup,
  Select,
  DatePicker,
  InputNumber,
  TextArea,
  MonthPicker,
  notify,
  confirm,
} from '../../../components/Inputs'

import { LoaderProgress, ErrorIcon } from '../../../components/Icons'
import { useGetItem, useSave, useRemove, create as init } from '../../../api/payments'
import { useDictsForSelects } from '../../../api/dicts'

const validationSchema = Yup.object().shape({
  date: Yup.date().required('Обязательное поле!'),
  account_id: Yup.string().required('Обязательное поле!'),
  paymentstype_id: Yup.string().required('Обязательное поле!'),
  sum: Yup.number().required('Обязательное поле!').moreThan(0, 'Должно быть больше 0'),
  comment: Yup.string().max(8000, 'Слишком длинное!').nullable(),
})

const PaymentForm = ({ id, navigate }) => {
  // Data
  const { data, loading, error } = useGetItem(id)
  const [save, { loading: saveLoading }] = useSave()
  const [remove, { loading: removeLoading }] = useRemove(id)

  // Prepare
  const payment = useMemo(() => {
    if (data && id !== 'new') {
      const { account, user, paymentsType, ...payment } = data
      return {
        ...payment,
        paymentstype_id: paymentsType.id,
        account_id: account.id,
        user: user.name,
      }
    }
    return data
  }, [data, id])

  // Dicts
  const {
    data: { accounts, paymentsTypes },
    dictsLoading,
    dictsError,
  } = useDictsForSelects()

  const create = useCallback(
    async ({ setValues, dirty }) => {
      if (dirty) {
        try {
          await confirm({
            title: 'Изменения не были сохранены',
            content: 'Уверены, что хотите продолжить?',
            okType: 'danger',
          })
        } catch {
          return
        }
      }

      setValues(init())
      navigate('/payments/new')
      notify({
        type: 'success',
        message: 'Успешно',
        description: 'Объект создан',
      })
    },
    [navigate]
  )

  if (loading || dictsLoading) return <LoaderProgress />
  if (error || dictsError) return <ErrorIcon />

  return (
    <Formik initialValues={payment} validationSchema={validationSchema} onSubmit={save}>
      {props => (
        <Form>
          <TitleFixed
            text='Затраты'
            withBackBtn
            commands={
              <ButtonGroup>
                <Button loading={removeLoading} icon='delete' type='danger' onClick={remove}>
                  Удалить
                </Button>
                <Button icon='plus' onClick={() => create(props)}>
                  Создать
                </Button>
                <Button loading={saveLoading} icon='save' type='primary' htmlType='submit'>
                  Сохранить
                </Button>
              </ButtonGroup>
            }
          />

          <FormItem
            label='Дата списания'
            component={DatePicker}
            name='date'
            placeholder='Выберите дату'
            allowClear={false}
          />

          <FormItem
            label='Период'
            component={MonthPicker}
            name='accounting_period'
            placeholder='Выберите дату'
            allowClear={false}
          />

          <FormItem
            showSearch
            label='Категория'
            component={Select}
            name='paymentstype_id'
            placeholder='Выберите категорию'
            options={paymentsTypes}
          />

          <FormItem
            label='Счет'
            component={Select}
            name='account_id'
            placeholder='Выберите счет'
            options={accounts}
          />

          <FormItem label='Сумма' component={InputNumber} name='sum' placeholder='Введите сумму' />

          <FormItem
            label='Комментарий'
            component={TextArea}
            name='description'
            placeholder='Комментарий по затрате'
            rows={4}
          />

          <FormItem
            disabled={true}
            label='Автор'
            component={Input}
            name='user'
            placeholder='Автор документа'
          />
        </Form>
      )}
    </Formik>
  )
}

export default PaymentForm
