import React, { useCallback, useMemo } from 'react'
import {
  Button,
  ButtonGroup,
  ReportFilter,
  DatesFilterDropdownProps,
  Table,
} from '../../components/Inputs'
import { TitleFixed } from '../../components/PageTemplate'
import { AccountsBalancesFilters } from './AccountsBalancesFilters'
import { useGetList, useGetAccountHistory } from '../../api/accountBalances'
import { Statistic } from 'antd'
import useFilters from '../../core/useFilter'
import { makeDocLink } from '../../core/const'
import { ErrorIcon } from '../../components/Icons'

const AccountHistory = ({ navigate, ...props }) => {
  const {
    data: balanceData,
    loading: balanceLoading,
    error: balanceError,
    refetch: refetchBalance,
  } = useGetList()
  const { data, loading, error, refetch } = useGetAccountHistory()
  const { filters, setFilters } = useFilters('AccountsMovement')

  // Callbacks
  const update = useCallback(() => {
    refetch()
    refetchBalance()
  }, [refetch, refetchBalance])

  const setFilterDate = useCallback(
    value => setFilters({ ...filters, dateFromHistory: value[0] }),
    [filters, setFilters]
  )

  const rowKey = useCallback(record => `${record.owner_id} ${record.sum}`, [])

  const backButtonOnClick = useCallback(() => {
    setFilters({ ...filters, accountId: undefined })
    navigate('../')
  }, [filters, setFilters, navigate])

  //state
  const accountId = useMemo(() => {
    //если страница была перезагружена то нужно отправить в фильтры айди заново
    if (!filters.accountId) {
      setFilters({ ...filters, accountId: parseInt(props.id) })
    }
    return parseInt(filters.accountId || props.id)
  }, [filters, props.id, setFilters])

  //баланс выбранного счета
  const balance = useMemo(
    () =>
      balanceLoading || balanceError ? '' : balanceData.find(b => b.account.id === accountId).sum,
    [balanceData, balanceLoading, balanceError, accountId]
  )

  const columns = useMemo(
    () => [
      {
        ...DatesFilterDropdownProps(setFilterDate, [false, true]),
        filteredValue: [filters.dateFromHistory, filters.date],
      },
      {
        title: 'Руб',
        dataIndex: 'sum',
        key: 'sum',
        align: 'right',
        onFilter: (value, record) => (value ? record.sum > 0 : record.sum <= 0),
        filters: [
          { text: 'Приход', value: true },
          { text: 'Расход', value: false },
        ],
        render: val => (
          <span
            style={{
              color: val > 0 ? 'var(--c-positive)' : 'var(--c-danger)',
            }}
          >
            {val}
          </span>
        ),
      },
      {
        title: 'Документ',
        dataIndex: 'docLink',
        key: 'docLink',
        render: (val, row) => makeDocLink(row, true),
      },
    ],
    [filters, setFilterDate]
  )

  return (
    <>
      <TitleFixed
        text='Баланс счетов'
        withBackBtn={true}
        backButtonOnClick={backButtonOnClick}
        commands={
          <ButtonGroup>
            <Button loading={loading} icon='sync' onClick={update}>
              Обновить
            </Button>
          </ButtonGroup>
        }
      />
      <AccountsBalancesFilters navigate={navigate} />
      <ReportFilter label='Баланс'>
        <Statistic
          value={balance}
          suffix='₽'
          valueStyle={{
            fontSize: 24,
            lineHeight: 1,
            color: balance < 0 ? 'var(--c-danger)' : 'var(--c-positive)',
          }}
        />
      </ReportFilter>

      {(error && <ErrorIcon />) || (
        <Table dataSource={data} columns={columns} rowKey={rowKey} loading={loading} />
      )}
    </>
  )
}

export default AccountHistory
