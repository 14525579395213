import React, { Component } from 'react'
import cx from 'classnames'
import {
  Input as AntdInput,
  InputNumber as AntdInputNumber,
  Select as AntdSelect,
  Checkbox as AntdCheckbox,
  Button,
  Modal,
  notification,
  Icon,
  Collapse,
  Tooltip,
  Typography,
  DatePicker as AntdDatePicker,
  Table as AntdTable,
  message,
} from 'antd'
import { getIn } from 'formik'

import ReactQuill, { Quill } from 'react-quill'
import ImageCompress from 'quill-image-compress'

import moment from 'moment'
import 'react-quill/dist/quill.snow.css'

import { Field, formikInput } from './FormikHocs'
import './Inputs.css'
import { DEFAULT_DATE_FORMAT, LONG_DATE_FORMAT } from '../core/const'

export { Button, Collapse, Icon } from 'antd'
export const Title = Typography.Title
export const CollapsePanel = Collapse.Panel
export const ButtonGroup = Button.Group

Quill.register('modules/imageCompress', ImageCompress)

message.config({
  top: 50,
  duration: 2,
  maxCount: 3,
})

export const notify = options => {
  let key = Math.random().toString()
  let { type, ...other } = options

  return window.innerWidth > 768
    ? notification[type]({
        ...other,
        key,
        placement: 'bottomRight',
        onClick: () => {
          notification.close(key)
        },
      })
    : message[type](other.description)
}

export const confirm = confirm =>
  new Promise((resolve, reject) => {
    Modal.confirm({
      ...confirm,
      cancelText: 'Отмена',
      onOk: () => resolve(),
      onCancel: () => reject(),
    })
  })

const { Option } = AntdSelect

//временно
export class HTMLContainer extends Component {
  render() {
    return (
      <div
        className='html-container'
        {...this.props}
        dangerouslySetInnerHTML={{ __html: this.props.html }}
      ></div>
    )
  }
}

export const GridFormItem = ({ template = '1fr 2fr', label, children }) => (
  <div
    className='grid-form-item'
    style={{
      gridTemplateColumns: template,
    }}
  >
    <label>{label}</label>
    {children}
  </div>
)

export const FormItem = ({ label, noLabel, required, ...props }) => {
  return (
    <div className={'form-item'} style={{ display: 'grid' }}>
      {noLabel ? '' : <label className='control-label'>{label + (required ? ' *' : '')}</label>}

      <Field {...props} />
    </div>
  )
}

const inputWithErrors = InnerComponent => ({ hasError, error, className, ...props }) => {
  return (
    <div className={'formik-input ' + (hasError ? 'hasError ' : '') + (className ? className : '')}>
      <Tooltip placement='top' title={error}>
        <InnerComponent {...props} />
      </Tooltip>
    </div>
  )
}

export const withMoment = (Component, defaultFormat = LONG_DATE_FORMAT) => ({
  startDay,
  endDay,
  onChange,
  value,
  ...props
}) => (
  <Component
    format={defaultFormat}
    {...props}
    value={value ? new moment(value) : null}
    onChange={value => {
      if (value) {
        value = (startDay && value.startOf('day')) || (endDay && value.endOf('day')) || value
      }
      if (onChange) {
        onChange(value)
      }
    }}
  />
)

const { TextArea: AntdTextArea } = AntdInput

export const Input = formikInput(inputWithErrors(AntdInput))
export const TextArea = formikInput(inputWithErrors(AntdTextArea))
export const InputNumber = formikInput(inputWithErrors(AntdInputNumber))
export const Password = formikInput(inputWithErrors(AntdInput.Password))
export const DatePicker = formikInput(inputWithErrors(withMoment(AntdDatePicker)))

export const MonthPicker = formikInput(
  inputWithErrors(withMoment(AntdDatePicker.MonthPicker, 'MMMM'))
)

export const RangePicker = inputWithErrors(
  class RangePickerFormikInput extends Component {
    onChange = newValue => {
      let { nameStart, nameEnd } = this.props
      this.props.form.setFieldValue(nameStart, newValue[0].startOf('day'))
      this.props.form.setFieldValue(nameEnd, newValue[1].endOf('day'))

      if (this.props.onChange) {
        this.props.onChange(newValue, this.props.form)
      }
    }

    render() {
      let { field, form, nameStart, nameEnd, ...props } = this.props

      let hasError =
        (getIn(form.errors, nameStart) && getIn(form.touched, nameStart)) ||
        (getIn(form.errors, nameEnd) && getIn(form.touched, nameEnd))
      let error = hasError
        ? getIn(form.errors, nameStart) + ' / ' + getIn(form.errors, nameEnd)
        : ''
      let value = [moment(getIn(form.values, nameStart)), moment(getIn(form.values, nameEnd))]
      props = {
        ...props,
        ...field,
        hasError,
        error,
        onChange: this.onChange,
        value,
        format: LONG_DATE_FORMAT,
        placeholder: ['Дата начала', 'Дата окончания'],
      }

      return <AntdDatePicker.RangePicker {...props} />
    }
  }
)

export const Checkbox = ({ field, ...props }) => (
  <AntdCheckbox {...field} {...props} checked={field.value} />
)

export const SelectWithOptions = ({ options = [], loading, ...props }) => (
  <AntdSelect
    {...props}
    showArrow={true}
    style={{ width: '100%' }}
    optionFilterProp='label'
    filterOption={(input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    notFoundContent={<span style={{ paddingLeft: '15px', fontStyle: 'italic' }}>Не найдено</span>}
  >
    {!loading &&
      options.map((c, i) => (
        <Option key={i} value={c.value}>
          {c.label}
        </Option>
      ))}
  </AntdSelect>
)

export const Select = formikInput(inputWithErrors(SelectWithOptions))

export class ReactQuillContainer extends Component {
  render() {
    return <ReactQuill {...this.props} theme='snow' />
  }
}
ReactQuillContainer.defaultProps = {
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image', 'video'],
    ],
    imageCompress: {
      quality: 0.7,
      maxWidth: 720,
      imageType: 'image/jpeg',
      debug: true,
    },
  },
}

export const RichText = formikInput(ReactQuillContainer)

export const TableSearchDropdownProps = (
  dataIndex,
  onFilter = (value, record) =>
    getIn(record, dataIndex) &&
    getIn(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase())
) => {
  let inputRef
  const handleSearch = (selectedKeys, confirm) => {
    confirm()
  }

  const handleReset = clearFilters => {
    clearFilters()
  }

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <AntdInput
          ref={input => {
            inputRef = input
          }}
          placeholder='Поиск'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon='search'
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Найти
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Отмена
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type='search' style={{ color: filtered ? 'var(--c-positive)' : undefined }} />
    ),
    onFilter: onFilter,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => inputRef.focus())
      }
    },
  }
}

export const DatesFilterDropdownProps = (setFilters = false, disabled = [false, false]) => {
  let handleSearch = (selectedKeys, confirm) => {
    setFilters && setFilters(selectedKeys)
    confirm()
  }

  return {
    title: 'Дата',
    dataIndex: 'date',
    key: 'date',
    render: val => moment(val).format(DEFAULT_DATE_FORMAT),

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, ...props }) => {
      selectedKeys = selectedKeys.length && selectedKeys
      return (
        <div style={{ padding: 8 }}>
          <AntdDatePicker
            allowClear={false}
            format={LONG_DATE_FORMAT}
            placeholder='Дата от'
            onChange={value => {
              setSelectedKeys([value.startOf('day'), selectedKeys[1]])
            }}
            value={new moment(selectedKeys[0]).startOf('day')}
            style={{ width: 150, marginBottom: 8, display: 'block' }}
            disabled={disabled[0]}
          />

          <AntdDatePicker
            allowClear={false}
            format={LONG_DATE_FORMAT}
            placeholder='Дата до'
            onChange={value => setSelectedKeys([selectedKeys[0], value.endOf('day')])}
            value={new moment(selectedKeys[1]).endOf('day')}
            style={{ width: 150, marginBottom: 8, display: 'block' }}
            disabled={disabled[1]}
          />

          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon='search'
            size='small'
            style={{ width: 150, marginRight: 8 }}
          >
            Найти
          </Button>
          {/* <Button
            onClick={() => handleReset(clearFilters, setSelectedKeys)}
            size="small"
            style={{ width: 90 }}
          >
            Отмена
          </Button> */}
        </div>
      )
    },
    filterIcon: (
      <Icon
        type='filter'
        className='big-table-filter'
        theme='filled'
        style={{ color: 'var(--c-positive)', fontSize: '18px' }}
      />
    ),
  }
}

export class ModalLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
    }
  }

  show = (resolve, reject) => {
    this.setState({ show: true })
  }

  hide = () => {
    this.setState({ show: false })
  }

  onClose = () => {
    this.hide()
  }

  render() {
    if (!this.state.show) {
      return null
    }

    let { render, ...props } = this.props
    return (
      <Modal {...props} visible={true} onCancel={this.onClose} onOk={this.onClose}>
        {render(props)}
      </Modal>
    )
  }
}

export const TableMultiSelect = formikInput(
  inputWithErrors(
    class TableMultiSelectClass extends Component {
      onChange = (selectedRowKeys, selectedRows) => {
        this.props.onChange(selectedRowKeys)
      }

      onRowClick = item => {
        let selectedRowKeys = [...this.props.value]
        let toggleIndex = this.props.value.findIndex(i => i === item.value)
        if (toggleIndex > -1) {
          selectedRowKeys.splice(toggleIndex, 1)
        } else {
          selectedRowKeys.push(item.value)
        }
        this.props.onChange(selectedRowKeys)
      }

      render() {
        return (
          <AntdTable
            locale={{ filterReset: 'Отмена', emptyText: 'Нет данных' }}
            columns={this.props.columns}
            rowSelection={{
              onChange: this.onChange,
              selectedRowKeys: this.props.value,
              selections: false,
            }}
            dataSource={this.props.options}
            pagination={false}
            style={{
              background: '#fff',
            }}
            bordered={false}
            rowKey='value'
            onRow={record => ({ onClick: e => this.onRowClick(record) })}
            size='small'
          />
        )
      }
    }
  )
)

export const Table = ({
  onRow,
  className,
  locale = { filterReset: 'Отмена', emptyText: 'Нет данных' },
  pagination = false,
  style = { background: '#fff' },
  ...props
}) => {
  return (
    <AntdTable
      locale={locale}
      style={style}
      pagination={pagination}
      onRow={onRow}
      className={cx(className, { 'table-clickable-rows': onRow })}
      {...props}
    />
  )
}

export const ReportFilter = ({ label, children }) => (
  <div className='report-filter'>
    <label>{label}</label>
    {children}
  </div>
)
