import React from 'react'
import { Router, Redirect } from '@reach/router'

import withAuthChecker from '../core/withAuthChecker'
import { useProfile } from '../api/profile'
import { LoginPage, LogoutPage } from '../pages/LoginPage'
import Profile from './Profile'
import { Users, UsersForm } from './Users/'
import { News, NewsForm } from './News/'
import { Salaries, SalaryForm } from './documents/Salaries/'
import { PaymentReceipts, PaymentReceiptsForm } from './documents/PaymentReceipts'
import { Payments, PaymentForm, PaymentsReport } from './documents/Payments'
import { Transfers, TransferForm } from './documents/Transfers'
import { Freezings, FreezingForm } from './documents/Freezings'
import { ManualAdjustments, ManualAdjustmentForm } from './documents/ManualAdjustments'
import { VisitLists, VisitListForm } from './documents/VisitLists'

import AccountsBalances from './AccountsBalances/'
import ClientsBalances from './ClientsBalances/AdminReport'
import ClientBalance from './ClientsBalances/ClientReport'

import EmployeeMain from './EmployeeMain'
import AdminMain from './AdminMain'

import Requisites from './Requisites'
import Video from './Video'

import PageTemplate, { TitleFixed } from '../components/PageTemplate'

const InTest = ({ title }) => (
  <div style={{ textAlign: 'center', paddingTop: '30px' }}>
    <TitleFixed>{title}</TitleFixed>
    <h2>Скоро будет что-то новое!</h2>
    <p>Раздел на тестировании</p>
  </div>
)

const Pages = () => {
  const { IS_EMPLOYEE, IS_ADMIN, IS_CLIENT } = useProfile()
  return (
    <Router primary={false} component={PageTemplate}>
      <LogoutPage path='/logout' />
      <Profile path='/profile' />

      <Users path='/users' />
      <UsersForm path='/users/:id' />

      <News path='/news' />
      <NewsForm path='/news/:id' />

      <Salaries path='/salaries' />
      <SalaryForm path='/salaries/:id' />

      <PaymentReceipts path='/payment-receipts' />
      <PaymentReceiptsForm path='/payment-receipts/:id' />

      <Payments path='/payments' />
      <PaymentsReport path='/payments-report' />
      <PaymentForm path='/payments/:id' />

      <Transfers path='/transfers' />
      <TransferForm path='/transfers/:id' />

      <Freezings path='/freezings' />
      <FreezingForm path='/freezings/:id' />

      <ManualAdjustments path='/manual-adjustments' />
      <ManualAdjustmentForm path='/manual-adjustments/:id' />

      <VisitLists path='/visit-lists' />
      <VisitListForm path='/visit-lists/:id' />

      <AccountsBalances path='accounts-balances/*' />
      <ClientsBalances path='clients-balances/*' />
      <ClientBalance path='client-balance' />

      <Requisites path='client-balance/requisites' />
      <Video path='/video' />

      <InTest path='client-stat' title='Статистика' />

      <AdminMain path='admin-main' />
      <EmployeeMain path='employee-main' />

      <Redirect
        from='*'
        to={
          IS_CLIENT
            ? '/news'
            : IS_EMPLOYEE
            ? '/employee-main'
            : IS_ADMIN
            ? '/admin-main'
            : '/profile'
        }
        noThrow
      />
    </Router>
  )
}

export default withAuthChecker(LoginPage, Pages)
