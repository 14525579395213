import React, { useMemo } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { TitleFixed } from '../../../components/PageTemplate'
import {
  Input,
  FormItem,
  Button,
  ButtonGroup,
  Select,
  DatePicker,
  InputNumber,
  TextArea,
} from '../../../components/Inputs'

import { LoaderProgress, ErrorIcon } from '../../../components/Icons'
import { useGetItem, useSave, useRemove } from '../../../api/manualAdjustments'
import { useDictsForSelects } from '../../../api/dicts'

const validationSchema = Yup.object().shape({
  date: Yup.date().required('Обязательное поле!'),
  edit_user_id: Yup.string().required('Обязательное поле!'),
  sum: Yup.number().required('Обязательное поле!'),
  comment: Yup.string().max(8000, 'Слишком длинное!'),
})

const ManualAdjustmentForm = ({ id, navigate }) => {
  // Data
  const { data, loading, error } = useGetItem(id)
  const [save, { loading: saveLoading }] = useSave()
  const [remove, { loading: removeLoading }] = useRemove(id)

  // Prepare
  const manualAdjustments = useMemo(() => {
    if (data && id !== 'new') {
      let { user, edit_user, ...manualAdjustment } = data
      return {
        ...manualAdjustment,
        edit_user_id: edit_user.id,
        user: user.name,
      }
    }
    return data
  }, [data, id])

  // Dicts
  const {
    data: { clients },
    dictsLoading,
    dictsError,
  } = useDictsForSelects()

  if (loading || dictsLoading) return <LoaderProgress />
  if (error || dictsError) return <ErrorIcon />

  return (
    <Formik initialValues={manualAdjustments} validationSchema={validationSchema} onSubmit={save}>
      {props => {
        return (
          <Form>
            <TitleFixed
              text='Корректировка'
              withBackBtn
              commands={
                <ButtonGroup>
                  <Button loading={removeLoading} icon='delete' type='danger' onClick={remove}>
                    Удалить
                  </Button>
                  <Button loading={saveLoading} icon='save' type='primary' htmlType='submit'>
                    Сохранить
                  </Button>
                </ButtonGroup>
              }
            />
            <FormItem
              label='Дата'
              component={DatePicker}
              name='date'
              placeholder='Выберите дату'
              allowClear={false}
            />

            <FormItem
              showSearch
              label='Клиент'
              component={Select}
              name='edit_user_id'
              placeholder='Выберите клиента'
              options={clients}
            />

            <FormItem
              label='Сумма'
              component={InputNumber}
              name='sum'
              placeholder='Введите сумму'
            />

            <FormItem
              label='Комментарий'
              component={TextArea}
              name='description'
              placeholder='Комментарий по корректировке'
              rows={4}
            />

            <FormItem
              disabled={true}
              label='Автор'
              component={Input}
              name='user'
              placeholder='Автор документа'
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default ManualAdjustmentForm
