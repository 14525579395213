import React, { useMemo } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { TitleFixed } from '../../../components/PageTemplate'
import {
  Input,
  FormItem,
  Button,
  ButtonGroup,
  Select,
  Checkbox,
  RangePicker,
  TextArea,
} from '../../../components/Inputs'

import { LoaderProgress, ErrorIcon } from '../../../components/Icons'
import { useGetItem, useSave, useRemove } from '../../../api/freezing'
import { useDictsForSelects } from '../../../api/dicts'

const validationSchema = Yup.object().shape({
  date_start: Yup.date().required('Обязательное поле!'),
  date_end: Yup.date().required('Обязательное поле!'),
  child_id: Yup.string().required('Обязательное поле!'),
  comment: Yup.string().max(8000, 'Слишком длинное!'),
})

const FreezingForm = ({ id }) => {
  // Data
  const { data, loading, error } = useGetItem(id)
  const [save, { loading: saveLoading }] = useSave()
  const [remove, { loading: removeLoading }] = useRemove(id)

  // Prepare
  const freezings = useMemo(() => {
    if (data && id !== 'new') {
      const { user, child, ...freezing } = data
      return { ...freezing, child_id: child.id, user: user.name }
    }
    return data
  }, [data, id])

  // Dicts
  const {
    data: { clientChildren },
    dictsLoading,
    dictsError,
  } = useDictsForSelects()

  if (loading || dictsLoading) return <LoaderProgress />
  if (error || dictsError) return <ErrorIcon />

  return (
    <Formik validationSchema={validationSchema} initialValues={freezings} onSubmit={save}>
      {props => {
        return (
          <Form>
            <TitleFixed
              text='Заморозка'
              withBackBtn
              commands={
                <ButtonGroup>
                  <Button loading={removeLoading} icon='delete' type='danger' onClick={remove}>
                    Удалить
                  </Button>
                  <Button loading={saveLoading} icon='save' type='primary' htmlType='submit'>
                    Сохранить
                  </Button>
                </ButtonGroup>
              }
            />

            <FormItem
              label='Период'
              component={RangePicker}
              name='range'
              nameStart='date_start'
              nameEnd='date_end'
              allowClear={false}
            />

            <FormItem
              showSearch
              label='Ребенок'
              component={Select}
              name='child_id'
              placeholder='Выберите ребенка'
              options={clientChildren}
            />

            <FormItem label='Адаптация' component={Checkbox} name='adaptation' />

            <FormItem label='Комментарий' component={TextArea} name='comment' allowClear={true} />

            <FormItem
              disabled={true}
              label='Автор'
              component={Input}
              name='user'
              placeholder='Автор документа'
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default FreezingForm
