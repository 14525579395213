import React, { useState, useMemo, useCallback } from 'react'
import { Icon } from 'antd'
import moment from 'moment'

// TODO: onFilter как то нагляднее сделать чтоб не фильтровал по неактивным столбцам!!!
export const groupingColumn = ({ grouping, toggleGrouping, key, title, onFilter }) => ({
  title: () => {
    let active = grouping.includes(key)
    return (
      <>
        {active ? <Icon type='check-square' /> : <Icon type='border' />}{' '}
        <span style={{ color: active ? 'inherit' : '#aaa' }}> {title}</span>
      </>
    )
  },
  onHeaderCell: () => ({
    onClick: () => {
      toggleGrouping(key)
    },
  }),
  onFilter: grouping.includes(key) ? onFilter : () => true,
})

const initFilters = [
  moment().startOf('month').subtract(1, 'months'),
  moment().endOf('month').subtract(1, 'months'),
]
export const useDateReportFilter = (init = initFilters) => {
  const initData = useMemo(() => {
    return typeof init === 'function' ? init() : init
  }, [init])

  const [filters, setFilters] = useState(initData)
  return [filters, setFilters]
}

export const useGrouping = init => {
  const [grouping, setGrouping] = useState(init)

  const toggleGrouping = useCallback(
    value => {
      const index = grouping.indexOf(value)
      if (index > -1) {
        setGrouping(grouping.filter(item => item !== value))
      } else {
        setGrouping([...grouping, value])
      }
    },
    [grouping, setGrouping]
  )

  return [grouping, toggleGrouping, setGrouping]
}
