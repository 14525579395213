import { InMemoryCache } from '@apollo/client'

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        currentUser() {
          return setCurrentUser()
        },
        filters() {
          return setFilters()
        },
        createInit() {
          return setCreateInit()
        }
      },
    },
  },
})

export default cache

export const setCurrentUser = cache.makeVar()
export const setFilters = cache.makeVar()
export const setCreateInit = cache.makeVar()

