import React from 'react'

import { Collapse, CollapsePanel } from '../components/Inputs'
import { TitleFixed } from '../components/PageTemplate'

export default props => (
  <>
    <TitleFixed>Оплата</TitleFixed>

    <Collapse defaultActiveKey={['by-card', 'by-props']}>
      <CollapsePanel header='По номеру карты Сбербанк' key='by-card'>
        <p>
          Получатель: Карина Алексеевна И.
          <br />
          Номер карты: 4276 0600 5202 8118
        </p>
      </CollapsePanel>

      <CollapsePanel header='По реквизитам' key='by-props'>
        <p>
          Получатель: ИВАНОВА ЭЛЬЗА РУСТАМОВНА <br />
          Номер счета: 40817810906004201619 <br />
          <br />
          Банк получателя: БАШКИРСКОЕ ОТДЕЛЕНИЕ N8598 ПАО СБЕРБАНК
          <br />
          БИК: 048073601 <br />
          Кор. счет: 30101810300000000601 <br />
          КПП: 027802001 <br />
          ИНН: 7707083893 <br />
          SWIFT-код: SABRRUMM
        </p>
      </CollapsePanel>
    </Collapse>
  </>
)
