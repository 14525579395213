import React, { useMemo } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { TitleFixed } from '../../../components/PageTemplate'
import {
  Input,
  FormItem,
  Button,
  ButtonGroup,
  Select,
  DatePicker,
  InputNumber,
  TextArea,
} from '../../../components/Inputs'

import { LoaderProgress, ErrorIcon } from '../../../components/Icons'
import { useGetItem, useSave, useRemove } from '../../../api/transfers'
import { useDictsForSelects } from '../../../api/dicts'

const validationSchema = Yup.object().shape({
  date: Yup.date().required('Обязательное поле!'),
  account_id: Yup.string().required('Обязательное поле!'),
  account_id_to: Yup.string().required('Обязательное поле!'),
  sum: Yup.number().required('Обязательное поле!').moreThan(0, 'Должно быть больше 0'),
})

const TransferForm = ({ id, navigate }) => {
  // Data
  const { data, loading, error } = useGetItem(id)
  const [save, { loading: saveLoading }] = useSave()
  const [remove, { loading: removeLoading }] = useRemove(id)

  // Prepare
  const transfer = useMemo(() => {
    if (data && id !== 'new') {
      const { account, user, account_to, ...transfer } = data
      return {
        ...transfer,
        account_id: account.id,
        account_id_to: account_to.id,
        user: user.name,
      }
    }
    return data
  }, [data, id])

  // Dicts
  const {
    data: { accounts },
    dictsLoading,
    dictsError,
  } = useDictsForSelects()

  if (loading || dictsLoading) return <LoaderProgress />
  if (error || dictsError) return <ErrorIcon />

  return (
    <Formik initialValues={transfer} validationSchema={validationSchema} onSubmit={save}>
      {props => {
        return (
          <Form>
            <TitleFixed
              text='Перевод'
              withBackBtn
              commands={
                <ButtonGroup>
                  <Button loading={removeLoading} icon='delete' type='danger' onClick={remove}>
                    Удалить
                  </Button>
                  <Button loading={saveLoading} icon='save' type='primary' htmlType='submit'>
                    Сохранить
                  </Button>
                </ButtonGroup>
              }
            />
            <FormItem
              label='Дата перевода'
              component={DatePicker}
              name='date'
              placeholder='Выберите дату'
              allowClear={false}
            />

            <FormItem
              label='Счет списания'
              component={Select}
              name='account_id'
              placeholder='Выберите счет'
              options={accounts}
            />

            <FormItem
              label='Счет получения'
              component={Select}
              name='account_id_to'
              placeholder='Выберите счет'
              options={accounts}
            />

            <FormItem
              label='Сумма'
              component={InputNumber}
              name='sum'
              placeholder='Введите сумму'
            />

            <FormItem label='Комментарий' component={TextArea} name='comment' allowClear={true} />
            
            <FormItem
              disabled={true}
              label='Автор'
              component={Input}
              name='user'
              placeholder='Автор документа'
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default TransferForm
