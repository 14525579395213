import React from 'react'
import { TitleFixed } from '../components/PageTemplate'

import { VisitListMaker, NewsMaker } from '../widgets/Makers'
import VideoWidget from '../widgets/VideoWidget'
import s from '../widgets/widgets.module.css'

const EmployeeMain = props => {
  return (
    <>
      <TitleFixed>Главное</TitleFixed>

      <h3 className={s.widgetTitle} style={{ marginTop: 0 }}>
        Создать лист посещения по услуге
      </h3>
      <VisitListMaker defaultActiveKey={['main']} />

      <h3 className={s.widgetTitle}>Поделитесь чем-то с родителями</h3>
      <NewsMaker />

      <h3 className={s.widgetTitle}>Видеонаблюдение</h3>
      <VideoWidget />
    </>
  )
}

export default EmployeeMain
