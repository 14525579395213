import { useMemo } from 'react'
import { gql } from '@apollo/client'
import get from 'lodash.get'
import { useQueryExt } from '../core/apolloHooks'
import { ROLES_IDS } from '../core/const'
import moment from 'moment'

// TODO
// сейчас это как 2 разных запроса уходит, а должен только один уходить, и возвращаться в нужном виде для обоих случаев

export const DICTS_FOR_FILTERS = gql`
  query {
    roles {
      value: local_name
      text: local_name
      id
    }

    structure {
      value: id
      text: name
    }

    accounts {
      value: id
      text: name
    }

    paymentsTypes {
      value: id
      text: name
    }

    servicesTypes {
      value: id
      text: name
      parent_servicetype_id
      is_parent_servicetype
    }

    salaryRecipients: users(
      filters: {
        role_id: ["${ROLES_IDS.employee}", "${ROLES_IDS.admin}"]
        is_active: true
      }
    ) {
      value: id
      text: name
    }
  }
`

export const useDictsForFilters = () => {
  const { data = {}, error: dictsError, loading: dictsLoading, ...result } = useQueryExt(
    DICTS_FOR_FILTERS
  )

  return { data, dictsError, dictsLoading, ...result }
}

export const DICTS_FOR_SELECTS = gql`
  query {
    roles {
      value: id
      label: local_name
    }

    structure {
      value: id
      label: name
      level
    }
    
    accounts {
      value: id
      label: name
    }

    paymentsTypes {
      value: id
      label: name
    }

    servicesTypes {
      value: id
      label: name
      parent_servicetype_id
      is_parent_servicetype
    }

    salaryRecipients: users(
      filters: {
        role_id: ["${ROLES_IDS.employee}", "${ROLES_IDS.admin}"]
        is_active: true
      }
    ) {
      value: id
      label: name
    }

    clients: users(
      filters: { role_id: "${ROLES_IDS.client}", is_active: true }
    ) {
      value: id
      label: name
    }

    clientChildren: clientChildren(
      filters: { is_active: true }
    ) {
      value: id
      label: name
    }
  }
`

// TODO - надо клиентов тоже пулить и детей вот тут сверху ^^^
// а еще может справочники должны в refetch тоже участвовать?

export const useDictsForSelects = () => {
  const { data = {}, error: dictsError, loading: dictsLoading, ...result } = useQueryExt(
    DICTS_FOR_SELECTS
  )

  return { data, dictsError, dictsLoading, ...result }
}

export const CHILD_FOR_NEWS = gql`
  query {
    clientChildren(
      filters: { is_active: true }
      orderBy: [{ column: "structure_id" }, { column: "name" }]
    ) {
      id
      value: id
      label: name
      structure {
        id
        name
      }
    }
  }
`

// TODO: надо объединить оба запроса и сделать пуллинг
export const useChildForNews = () => {
  const { data = [], error: childError, loading: childLoading, ...result } = useQueryExt(
    CHILD_FOR_NEWS,
    {
      fetchPolicy: 'network-only',
    }
  )

  const clientChildren = useMemo(() => get(data, 'clientChildren', []), [data])

  return { data: clientChildren, childError, childLoading, ...result }
}

export const CHILDREN_WITH_SERVICES = gql`
  query {
    clientChildren(
      filters: { is_active: true }
      orderBy: [{ column: "structure_id" }, { column: "name" }]
    ) {
      id
      value: id
      label: name
      services {
        servicetype_id
        name
        date_start
        date_end
        parent_servicetype_id
      }
      structure {
        id
        name
      }
    }
  }
`

export const useGetChildrenWithServices = () => {
  const { data = [], error: childrenError, loading: childrenLoading, ...result } = useQueryExt(
    CHILDREN_WITH_SERVICES,
    {
      fetchPolicy: 'network-only',
    }
  )

  const clientChildren = useMemo(() => get(data, 'clientChildren', []), [data])

  return { clientChildren, childrenError, childrenLoading, ...result }
}

// Дети из группы с действующей на дату услугой
export const childrenByStructureAndService = (
  clientChildren,
  structure_id,
  servicetype_id,
  date
) => {
  if (!clientChildren || !structure_id || !servicetype_id || !date) return []

  return clientChildren.filter(child => {
    return (
      child.structure.id === structure_id &&
      child.services.find(serv => {
        return (
          (serv.servicetype_id === servicetype_id ||
            serv.parent_servicetype_id === servicetype_id) &&
          //Если дата визит листа находится в периоде улуги ребенка
          (moment(date)
            .startOf('day')
            .isBetween(
              moment(serv.date_start).startOf('day'),
              moment(serv.date_end).endOf('day')
            ) ||
            //Или если услуга не приостановлена и дата визит листа больше даты начала услуги
            (moment(date).startOf('day').isSameOrAfter(moment(serv.date_start).startOf('day')) &&
              !serv.date_end))
        )
      })
    )
  })
}
