import React from 'react'
import { Icon } from 'antd'

export const LoaderProgress = () => (
  <div className='loader-progress'>
    <Icon type='loading' />
  </div>
)

export const ErrorIcon = () => (
  <div className='loader-progress'>
    <Icon type='warning' />
  </div>
)
