import { docsRoutes } from './const'
import { useProfile } from '../api/profile'

export const useGetMenuByUser = () => {
  const { IS_ADMIN, IS_EMPLOYEE, IS_CLIENT } = useProfile()

  if (IS_CLIENT) {
    return [
      { to: '/news', text: 'Новости', icon: 'bell' },
      { to: '/video', text: 'Камера', icon: 'right-circle' },
      { to: '/client-balance', text: 'Баланс', icon: 'dollar' },
      { to: '/client-stat', text: 'Статистика', icon: 'pie-chart' },
      {
        to: '/profile',
        text: 'Профиль',
        icon: 'user',
        className: 'hidden-web',
      },
    ]
  } else if (IS_EMPLOYEE) {
    return [
      { to: '/employee-main', text: 'Главное', icon: 'appstore' },
      { to: '/news', text: 'Новости', icon: 'bell' },
      { to: '/visit-lists', text: 'Журналы', icon: 'schedule' },
      { to: '/salaries', text: 'Статистика', icon: 'pie-chart' },
      {
        to: '/profile',
        text: 'Профиль',
        icon: 'user',
        className: 'hidden-web',
      },
    ]
  } else if (IS_ADMIN) {
    return [
      { to: '/admin-main', text: 'Главное', icon: 'appstore' },
      { to: '/news', text: 'Новости', icon: 'bell' },
      {
        text: 'Баланс',
        icon: 'dollar',
        submenu: [
          {
            to: '/accounts-balances',
            icon: 'credit-card',
            text: 'Баланс счетов',
          },
          { to: '/clients-balances', icon: 'idcard', text: 'Баланс клиентов' },
        ],
      },
      { text: 'Документы', icon: 'file-text', submenu: docsRoutes },
      {
        to: '/users',
        text: 'Пользователи',
        icon: 'usergroup-add',
        className: 'hidden-mobile',
      },
      {
        text: 'Настройки',
        icon: 'setting',
        className: 'hidden-web',
        submenu: [
          { to: '/users', text: 'Пользователи', icon: 'usergroup-add' },
          { to: '/profile', text: 'Профиль', icon: 'user' },
        ],
      },
    ]
  } else {
    return []
  }
}
