import React, { useCallback, useEffect } from 'react'

import ClientsHistory from './ClientsHistory'
import { TitleFixed } from '../../components/PageTemplate'
import { Button, ButtonGroup } from '../../components/Inputs'
import { useProfile } from '../../api/profile'
import ReactSwipeEvents from 'react-swipe-events'
import { Statistic, Icon, Card } from 'antd'
import useFilters from '../../core/useFilter'

const ClientsBalanceReport = ({ navigate }) => {
  const { setDefaultFilter } = useFilters('ClientsAccountsMovement')
  //ставим дефолтное значение фильтров
  useEffect(() => setDefaultFilter, [])

  const { currentUser } = useProfile()

  const bill = useCallback(() => {
    navigate('/client-balance/requisites')
  }, [navigate])

  const balanceRender = useCallback(
    ({ balance, update }) => (
      <ReactSwipeEvents
        onSwipedDown={(e, x, y) => {
          if (window.scrollY <= 0) {
            update()
          }
        }}
      >
        <Card
          style={{
            textAlign: 'center',
            marginTop: -20,
            background: balance < 0 ? 'var(--c-danger)' : 'var(--c-positive)',
            color: '#fff',
            fontSize: '16px',
          }}
        >
          <Icon
            type='user'
            style={{
              fontSize: '45px',
              display: 'block',
              marginTop: 20,
              marginBottom: 5,
            }}
          />

          {currentUser.name}

          <Statistic
            value={balance}
            groupSeparator=' '
            suffix='руб.'
            style={{
              marginBottom: 25,
              color: '#fff',
            }}
          />

          <Button ghost icon='plus-circle' onClick={bill}>
            Пополнить
          </Button>
          <Icon
            type='ellipsis'
            style={{
              fontSize: '30px',
              display: 'block',
              marginTop: 20,
            }}
            onClick={() => window.scroll(0, 290)}
          />
        </Card>
      </ReactSwipeEvents>
    ),
    []
  )

  const titleRender = useCallback(
    ({ update, loading }) => (
      <TitleFixed
        text='Баланс'
        commands={
          <ButtonGroup>
            <Button loading={loading} icon='sync' onClick={update}>
              Обновить
            </Button>
            <Button type='primary' icon='plus-circle' onClick={bill}>
              Пополнить
            </Button>
          </ButtonGroup>
        }
      />
    ),
    []
  )

  return (
    <ClientsHistory balanceRender={balanceRender} titleRender={titleRender} id={currentUser.id} />
  )
}

export default ClientsBalanceReport
