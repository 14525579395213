import React, { useCallback, useState, useEffect } from 'react'
import { Link, useNavigate, useLocation, useParams } from '@reach/router'
import moment from 'moment'
import { Icon } from 'antd'
import cx from 'classnames'

import ISLLogo from '../assets/img/logo-isl.png'
import './PageTemplate.css'
import IosPwaNotification from './IosPwaNotification'

import useOrientation from '../core/useOrientation'
import { useProfile } from '../api/profile'
import { isPwa, isIos } from '../core/PWAConts'
import { useGetMenuByUser } from '../core/menu'
import useMobile from '../core/useMobile'

const PageContainer = ({ children }) => {
  const orientation = useOrientation()

  return (
    <div
      id='page-container'
      className={cx({ standalone: isPwa }, { web: !isPwa }, { ios: isIos }, orientation)}
    >
      <Sidebar />

      <div id='page-content'>
        {/* <div id="updater">
            <Icon type="reload" />
          </div> */}
        {children}
        <PageFooter />
      </div>
    </div>
  )
}

export default PageContainer

const Sidebar = () => {
  return (
    <div id='sidebar'>
      <div className='sidebar-menu-logo'>
        <img src='/logo.png' alt='' />
      </div>

      <Menu />
      <User />
    </div>
  )
}

const Menu = () => {
  const menu = useGetMenuByUser()
  const location = useLocation()
  const [openSubmenu, setOpenSubmenu] = useState(false)
  const mobile = useMobile()

  useEffect(() => {
    if (mobile) {
      setOpenSubmenu(false)
    }
  }, [location, mobile])

  return (
    <>
      {
        //TODO: move it into PageContainer. Сейчас это тут потому что надо скрывать, когда меню открывается
      }
      {!openSubmenu && mobile && <IosPwaNotification />}

      <nav className='menu'>
        {menu.map((item, index) => {
          return item.submenu ? (
            <SubMenu
              {...item}
              key={item.text}
              openSubmenu={openSubmenu}
              setOpenSubmenu={setOpenSubmenu}
            />
          ) : (
            <MenuItem {...item} key={index} />
          )
        })}
      </nav>
    </>
  )
}

const SubMenu = ({ icon, text, submenu, className, openSubmenu, setOpenSubmenu }) => {
  const isOpen = text === openSubmenu
  const mobile = useMobile()

  return (
    <div className={cx('submenu-container', { isOpen }, className)}>
      <div className='menu-item submenu-item' onClick={() => setOpenSubmenu(!isOpen && text)}>
        {icon ? <Icon type={icon}></Icon> : null}
        <span className='menu-item-text'>{text}</span>
        <Icon
          className='submenu-anchor hidden-mobile'
          type={isOpen ? 'up' : 'down'}
          style={{ fontSize: '0.9em' }}
        ></Icon>
      </div>

      <div className='submenu-layer' onClick={() => mobile && setOpenSubmenu(false)}>
        <div className='submenu'>
          {submenu.map((item, index) => {
            return item.submenu ? (
              <SubMenu {...item} key={index} />
            ) : (
              <MenuItem {...item} key={index} />
            )
          })}
        </div>
      </div>
    </div>
  )
}

const MenuItem = ({ icon, text, to, theme, ...props }) => (
  <Link
    to={to}
    getProps={({ isCurrent, isPartiallyCurrent }) => {
      let className = 'menu-item ' + (props.className ? props.className : '')
      return {
        className: isPartiallyCurrent ? className + ' active' : className,
      }
    }}
  >
    {icon ? <Icon theme={theme} type={icon}></Icon> : null}
    <span className='menu-item-text'>{text}</span>
  </Link>
)

const User = () => {
  const { IS_AUTHORIZED, currentUser } = useProfile()

  return (
    <div className='bottom-menu'>
      {
        <>
          {IS_AUTHORIZED && <MenuItem icon='user' to='/profile' text={currentUser.name} />}
          <MenuItem icon='logout' to='/logout' text='Выйти' />
        </>
      }
    </div>
  )
}

export const TitleFixed = ({
  children,
  text,
  commands,
  withBackBtn,
  backButtonUrl,
  backButtonOnClick,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  if (withBackBtn && !backButtonUrl) {
    backButtonUrl = location.pathname
      .split('/')
      // eslint-disable-next-line
      .filter(i => i != params.id)
      .join('/')
  }

  const navigateBack = useCallback(() => {
    navigate(backButtonUrl)
  }, [backButtonUrl, navigate])

  return (
    <div className={cx('title-fixed', { 'with-back-btn': backButtonUrl })}>
      <h4 onClick={withBackBtn ? backButtonOnClick || navigateBack : undefined}>
        {withBackBtn && <Icon type='left' />}
        {text || children}
      </h4>
      <div className='main-buttons'>{commands}</div>
    </div>
  )
}

const PageFooter = props => (
  <footer>
    <a href='https://easy-kids.ru'>
      <b>EasyKids</b>
    </a>{' '}
    © {new moment().format('YYYY')} Все права защищены. Разработано{' '}
    <a href='http://isldesign.ru' target='_blank' rel='noopener noreferrer'>
      ISL<b>design</b>
    </a>
  </footer>
)

export const ISLLink = props => (
  <div className='isl-link'>
    <a href='http://isldesign.ru/' target='_blank' rel='noopener noreferrer'>
      <img src={ISLLogo} alt=''></img>
      <div className='isl-link-text'>
        {/* <span className='action'>Закажите приложение в</span> */}
        <span className='isldesign'>
          ISL<b>design</b>
        </span>
      </div>
    </a>
  </div>
)
