import React, { useCallback, useMemo } from 'react'
import { Table } from '../../../components/Inputs'
import { TitleFixed } from '../../../components/PageTemplate'

import {
  Button,
  ButtonGroup,
  TableSearchDropdownProps,
  DatesFilterDropdownProps,
} from '../../../components/Inputs'

import { ErrorIcon } from '../../../components/Icons'
import useFilters from '../../../core/useFilter'
import { useGetList } from '../../../api/paymentsReceipts'
import { useDictsForFilters } from '../../../api/dicts'

const PaymentReceipts = ({ navigate }) => {
  // Data
  const { data, loading, error, refetch } = useGetList()

  // Callbacks
  const edit = useCallback(row => navigate('/payment-receipts/' + row.id), [navigate])
  const create = useCallback(() => navigate('/payment-receipts/new'), [navigate])
  const update = useCallback(() => refetch(), [refetch])

  // Dicts
  const {
    data: { accounts },
    dictsError,
  } = useDictsForFilters()

  // Filters and Columns
  const { filters, setFilters } = useFilters('PaymentReceipts')
  const columns = useMemo(
    () => [
      {
        ...DatesFilterDropdownProps(),
        filteredValue: filters['date'],
      },
      {
        title: 'Счет зачисления',
        dataIndex: 'account.name',
        key: 'account.name',
        filters: accounts,
        onFilter: (value, record) => record.account.id === value,
        filteredValue: filters['account.name'],
      },
      {
        ...TableSearchDropdownProps('payer.name'),
        title: 'Плательщик',
        dataIndex: 'payer.name',
        key: 'payer.name',
        filteredValue: filters['payer.name'],
      },
      {
        title: 'Сумма',
        dataIndex: 'sum',
        key: 'sum',
      },
    ],
    [filters, accounts]
  )

  if (error || dictsError) return <ErrorIcon />

  return (
    <>
      <TitleFixed
        text='Оплата'
        commands={
          <ButtonGroup>
            <Button loading={loading} icon='sync' onClick={update}>
              Обновить
            </Button>
            <Button type='primary' icon='plus' onClick={create}>
              Создать
            </Button>
          </ButtonGroup>
        }
      />

      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        rowKey='id'
        onChange={(pagination, filters, sorter) => {
          setFilters(filters)
        }}
        onRow={record => ({ onClick: e => edit(record) })}
      />
    </>
  )
}

export default PaymentReceipts
