import React, { useMemo } from 'react'
import { Formik, Form } from 'formik'
import uuid from 'uuid'
import * as Yup from 'yup'
import get from 'lodash.get'

import { TitleFixed } from '../../components/PageTemplate'
import {
  FormItem,
  Input,
  InputNumber,
  Button,
  ButtonGroup,
  Password,
  Select,
  Checkbox,
  DatePicker,
  TextArea,
} from '../../components/Inputs'
import { EasyTableEmu, EasyArray } from '../../components/EasyTable'
import { Field } from '../../components/FormikHocs'

import { useGetItem, useSave } from '../../api/users'
import { useDictsForSelects } from '../../api/dicts'
import { LoaderProgress, ErrorIcon } from '../../components/Icons'
import { ROLES_IDS } from '../../core/const'
import { useProfile } from '../../api/profile'

const getSchema = id =>
  Yup.object().shape({
    name: Yup.string().min(3, 'Слишком короткое!').required('Обязательное поле!'),
    login: Yup.string().min(3, 'Слишком короткое!').required('Обязательное поле!'),
    role_id: Yup.string().required('Обязательное поле!'),
    structure: Yup.string().required('Обязательное поле!'),
    password:
      id === 'new'
        ? Yup.string().required('Обязательное поле!')
        : Yup.string().min(5, 'Не меньше 5 символов'),
    clientChildren: Yup.array().of(
      Yup.object().shape({
        services: Yup.array().of(
          Yup.object().shape({
            discount: Yup.number()
              .typeError('Должно быть числом')
              .min(0, 'Должно быть больше или 0')
              .max(100, 'Должно быть не больше 100')
              .integer('Должно быть целым')
              .required('Обязательное поле!'),
          }),
        ),
      }),
    ),
  })

export const UsersForm = ({ id }) => {
  const { data, loading, error } = useGetItem(id)
  const [save, { loading: saveLoading }] = useSave()
  const { currentUser, IS_MASTER_ADMIN } = useProfile()

  // Dicts
  const {
    data: { roles: initRoles, structure: initStructure, servicesTypes },
    dictsLoading,
    dictsError,
  } = useDictsForSelects()

  //фильтруем список ролей из справочника для текущего пользователя (для админа орг-ии все роли а для остальных только доступные)
  const roles = useMemo(() =>
    IS_MASTER_ADMIN || !initRoles
      ? initRoles
      : initRoles.filter(role => role.value !== ROLES_IDS.admin),
  )
  //фильтруем список структур из справочника для текущего пользователя разделяя для админов, сотрудников группы
  const structure = useMemo(
    () =>
      initStructure
        ? initStructure.filter(initStr => currentUser.structureIds.includes(initStr.value))
        : [],
    [initStructure],
  )
  const adminStructure = useMemo(() => structure.filter(str => str.level < 2), [structure])
  const employeeClientStructure = useMemo(
    () => (structure ? structure.filter(str => str.level >= 1) : []),
    [structure],
  )
  const groupStructure = useMemo(() => structure.filter(str => str.level === 2))

  // Prepare data
  const user = useMemo(() => {
    if (id !== 'new' && data) {
      const { role, structure, ...user } = data
      return {
        ...user,
        role_id: role.id,
        structure: structure.map(c => c.id),
      }
    }

    return data
  }, [data, id])

  // Архивация/активация пользлватлей
  const toggleActive = ({ values, setValues, handleSubmit }) => {
    values.is_active = !values.is_active
    setValues(values)
    handleSubmit(values)
  }

  // Валидация
  const validationSchema = useMemo(() => {
    return getSchema(id)
  }, [id])

  //disabled
  const disabled = useMemo(
    () =>
      id !== 'new' &&
      //админов может создавать и править только админ с организацией
      ((user?.role_id === ROLES_IDS.admin && !IS_MASTER_ADMIN) ||
        //у текущего пользователя должны быть все структуры юзера которого правим
        user?.structure.find(str => !currentUser.structureIds.includes(str))),

    [currentUser, id, user],
  )

  if (loading || dictsLoading) return <LoaderProgress />
  if (error || dictsError) return <ErrorIcon />

  return (
    <Formik initialValues={user} validationSchema={validationSchema} onSubmit={save}>
      {props => {
        return (
          <Form>
            <TitleFixed
              text='Пользователь'
              withBackBtn
              commands={
                <ButtonGroup>
                  {id === 'new' ? null : props.values.is_active ? (
                    <Button
                      loading={saveLoading}
                      icon='delete'
                      type='danger'
                      onClick={() => toggleActive(props)}
                      disabled={disabled}
                    >
                      В архив
                    </Button>
                  ) : (
                    <Button
                      loading={saveLoading}
                      icon='rollback'
                      type='danger'
                      onClick={() => toggleActive(props)}
                      disabled={disabled}
                    >
                      Восстановить
                    </Button>
                  )}
                  <Button
                    loading={saveLoading}
                    icon='save'
                    type='primary'
                    htmlType='submit'
                    disabled={disabled}
                  >
                    Сохранить
                  </Button>
                </ButtonGroup>
              }
            />
            <FormItem
              label='Имя пользователя'
              required
              component={Input}
              name='name'
              placeholder='Имя пользователя'
            />

            <FormItem
              label='Логин'
              required
              component={Input}
              name='login'
              placeholder='Логин пользователя'
            />

            <FormItem
              label='Пароль'
              required={id === 'new'}
              component={Password}
              name='password'
              placeholder={id === 'new' ? 'Пароль' : 'Новый пароль'}
            />

            <FormItem
              label='Телефон'
              component={Input}
              name='tel'
              placeholder='Телефон пользователя'
            />

            <FormItem
              label='Роль'
              required
              component={Select}
              name='role_id'
              placeholder='Выберите роль'
              options={roles}
              loading={dictsLoading}
              onChange={(val, { setFieldValue }) => {
                setFieldValue('structure', [])
              }}
            />

            <FormItem
              disabled={!props.values.role_id}
              label='Структура'
              component={Select}
              mode='multiple'
              name='structure'
              placeholder='Выберите структуру'
              options={
                props.values.role_id === ROLES_IDS.admin ? adminStructure : employeeClientStructure
              }
              loading={dictsLoading}
            />

            {props.values.role_id === ROLES_IDS.client ? (
              <>
                <FormItem label='История' component={TextArea} name='history' />

                <EasyArray
                  name='clientChildren'
                  addBtnContent='Добавить ребенка'
                  keyProp='id'
                  deleteBtnContent='Удалить ребенка'
                  willPush={() => ({
                    is_active: true,
                    id: uuid(),
                    services: [],
                  })}
                >
                  <FormItem
                    label='Имя ребенка'
                    required
                    component={Input}
                    name='name'
                    placeholder='Имя ребенка'
                  />

                  <FormItem
                    label='Группа'
                    component={Select}
                    name='structure_id'
                    placeholder='Выберите группу'
                    options={groupStructure}
                    loading={dictsLoading}
                    calcProps={(field, form) => ({
                      // TODO: Это заглушка, которая не дает менять структуру у ребенка у которого есть услуги.
                      // По уму нужно для кажой услуги сделать выбор структуры.
                      // Сейчас (временно) предлагается создавть нового ребенка
                      disabled: !!get(form.values, `${field.name.split('.')[0]}.services`)?.length,
                    })}
                  />
                  <FormItem
                    label='Дата рождения'
                    startDay={true}
                    component={DatePicker}
                    name='date_birthday'
                    placeholder='Выберите дату'
                  />
                  <FormItem
                    label='Дата заключения договора'
                    startDay={true}
                    component={DatePicker}
                    name='date_contract'
                    placeholder='Выберите дату'
                  />

                  <FormItem label='Активен' component={Checkbox} name='is_active' />

                  <EasyTableEmu
                    keyProp={row => row.servicetype_id + row.date_start}
                    style={{ gridTemplateColumns: '3fr 2fr 2fr 55px 35px' }}
                    name='services'
                    columns={['Услуга', 'Начало', 'Окончание', 'Скидка']}
                    willPush={() => ({
                      discount: 0,
                    })}
                  >
                    <Field
                      component={Select}
                      options={servicesTypes}
                      loading={dictsLoading}
                      name='servicetype_id'
                      placeholder='Выберите услугу'
                    />
                    <Field
                      component={DatePicker}
                      name='date_start'
                      placeholder='Выберите дату'
                      startDay={true}
                    />
                    <Field
                      component={DatePicker}
                      name='date_end'
                      placeholder='Выберите дату'
                      endDay={true}
                    />
                    <Field component={InputNumber} name='discount' placeholder='%' />
                  </EasyTableEmu>
                </EasyArray>
              </>
            ) : null}
          </Form>
        )
      }}
    </Formik>
  )
}

export default UsersForm
