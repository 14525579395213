import React, { useCallback, useMemo } from 'react'
import moment from 'moment'

import { Table } from '../../../components/Inputs'
import { TitleFixed } from '../../../components/PageTemplate'
import { Button, ButtonGroup, DatesFilterDropdownProps } from '../../../components/Inputs'

import { ErrorIcon } from '../../../components/Icons'
import useFilters from '../../../core/useFilter'
import { useProfile } from '../../../api/profile'
import { useGetList } from '../../../api/salaries'
import { useDictsForFilters } from '../../../api/dicts'

const Salaries = ({ navigate }) => {
  const { IS_ADMIN } = useProfile()

  // Data
  const { data, loading, error, refetch } = useGetList()

  // Callbacks
  const create = useCallback(() => navigate('/salaries/new'), [navigate])
  const update = useCallback(() => refetch(), [refetch])
  const edit = useCallback(row => navigate('/salaries/' + row.id), [navigate])

  // Dicts
  const {
    data: { accounts, salaryRecipients },
    dictsError,
  } = useDictsForFilters()

  // Filters and Columns
  const { filters, setFilters } = useFilters('Salaries')
  const columns = useMemo(
    () =>
      [
        {
          ...DatesFilterDropdownProps(),
          filteredValue: filters['date'],
        },
        {
          title: 'Период',
          dataIndex: 'accounting_period',
          key: 'accounting_period',
          render: val => moment(val).format('MMMM'),
          align: 'center',
        },
        IS_ADMIN && {
          filters: accounts,
          title: 'Счет списания',
          dataIndex: 'account.name',
          key: 'account.name',
          onFilter: (value, record) => record.account.id === value,
          filteredValue: filters['account.name'],
        },
        IS_ADMIN && {
          filters: salaryRecipients,
          title: 'Сотрудник',
          dataIndex: 'recipient.name',
          key: 'recipient.name',
          onFilter: (value, record) => record.recipient.id === value,
          filteredValue: filters['recipient.name'],
        },
        {
          title: 'Сумма',
          dataIndex: 'sum',
          key: 'sum',
          align: 'right',
        },
      ].filter(i => i),
    [IS_ADMIN, filters, accounts, salaryRecipients]
  )

  if (error || dictsError) return <ErrorIcon />

  return (
    <>
      <TitleFixed
        text='Зарплата'
        commands={
          <ButtonGroup>
            <Button loading={loading} icon='sync' onClick={update}>
              Обновить
            </Button>
            {IS_ADMIN ? (
              <Button type='primary' icon='plus' onClick={create}>
                Создать
              </Button>
            ) : null}
          </ButtonGroup>
        }
      />

      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        rowKey='id'
        onChange={(pagination, filters, sorter) => {
          setFilters(filters)
        }}
        onRow={record => ({ onClick: IS_ADMIN ? e => edit(record) : null })}
      />
    </>
  )
}

export default Salaries
