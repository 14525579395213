import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
import errorNotifier from '../core/errorNotifier'

const SIGN_IN = gql`
  mutation singin($login: String!, $password: String!) {
    singin(login: $login, password: $password)
  }
`

export const useSignin = onSuccess => {
  const [mutate, result] = useMutation(SIGN_IN)

  // Колбек аутентификации
  const signin = useCallback(
    async ({ login, password }) => {
      login = (login || '').trim()
      password = (password || '').trim()

      try {
        const token = await mutate({ variables: { login, password } })
        localStorage.setItem('token', token.data.singin)

        // Колбек для удачного signin
        onSuccess()
      } catch (err) {
        errorNotifier(err)
      }
    },
    [mutate, onSuccess]
  )

  return [signin, result]
}
