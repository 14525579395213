import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { useQueryExt, useMutationExt, useRemoveExt } from '../core/apolloHooks'
import { useParams } from '@reach/router'
import uuid from 'uuid'
import useFilters from '../core/useFilter'
import { useDataOrCreator } from '../core/useCreate'


export const create = (initData={}) => ({
  id: uuid(),
  date: new Date(),
  accounting_period: new Date(),
  user: undefined,
  account_id: undefined,
  paymentstype_id: undefined,
  description: undefined,
  sum: undefined,
  ...initData,
})

export const LIST_FRAGMENT = `fragment paymentListItem on Payment {
	id
  date
  accounting_period
  sum
  account{
    id
    name
  }
  
  paymentsType{
    id
    name
  }
}`

export const GET_LIST = gql`
  query PaymentsList($filters: Filter) {
    payments(filters: $filters) {
      ...paymentListItem
    }
  }
  ${LIST_FRAGMENT}
`

const useRefetchVariables = () => {
  const { filters } = useFilters('Payments')

  return {
    filters: {
      date: [
        { operator: '>=', value: filters.date[0] },
        { operator: '<=', value: filters.date[1] },
      ],
    },
  }
}

export const useGetList = () => {
  const variables = useRefetchVariables()

  return useQueryExt(GET_LIST, {
    variables,
    notifyOnNetworkStatusChange: true,
    listPath: 'payments',
  })
}

export const FRAGMENT = `fragment payment on Payment {
	id
  date
  accounting_period
  sum
  description
  account{
    id
    name
  }
  
  paymentsType{
    id
    name
  }

  user{
    id
    name
  }
}`

export const GET_ITEM = gql`
  query($id: String) {
    payments(id: $id) {
      ...payment
    }
  }
  ${FRAGMENT}
`

export const useGetItem = id => {
  const result = useQueryExt(GET_ITEM, {
    variables: { id },
    skip: id === 'new',
    objectPath: 'payments[0]',
  })

  const data = useDataOrCreator(result.data, create)

  return { ...result, data }
}

export const SAVE = gql`
  mutation SavePayment($data: PaymentInput!) {
    payment(payment: $data) {
      ...payment
    }
  }
  ${FRAGMENT}
`

export const useSave = () => {
  const { id } = useParams()
  const variables = useRefetchVariables()

  const [mutate, result] = useMutationExt(SAVE, {
    awaitRefetchQueries: true,
    refetchQueries: id === 'new' && [
      {
        query: GET_LIST,
        variables,
      },
    ],
  })

  // Prepare object: delete user
  const save = useCallback(
    async ({ user, ...data }) => {
      await mutate({
        variables: { data },
      })
    },
    [mutate]
  )

  return [save, result]
}

export const REMOVE = gql`
  mutation($id: String!) {
    deletePayment(id: $id)
  }
`

export const useRemove = id => {
  return useRemoveExt(REMOVE, {
    variables: { id },
    fieldName: 'Payment',
  })
}

export const REPORT_FRAGMENT = `fragment paymentReport on Payment {
  sum
  account{
    id
    name
  }
  
  paymentsType{
    id
    name
  }
}`

export const REPORT_QUERY = gql`
  query PaymentsList($filters: Filter, $grouping: [String]) {
    payments(filters: $filters, grouping: $grouping, sum: ["sum"], orderBy: null) {
      ...paymentReport
    }
  }
  ${REPORT_FRAGMENT}
`
export const useGetReport = ({ filters, grouping }) => {
  const result = useQueryExt(REPORT_QUERY, {
    listPath: 'payments',
    variables: {
      filters: {
        date: [
          { operator: '>=', value: filters[0] },
          { operator: '<=', value: filters[1] },
        ],
      },
      grouping,
    },
    notifyOnNetworkStatusChange: true,
  })
  return result
}
