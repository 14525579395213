import React, { useCallback, useMemo } from 'react'

import { Table } from '../../components/Inputs'
import { TitleFixed } from '../../components/PageTemplate'

import useFilters from '../../core/useFilter'
import { useGetList } from '../../api/users'
import { useDictsForFilters } from '../../api/dicts'
import { ErrorIcon } from '../../components/Icons'

import {
  Button,
  ButtonGroup,
  Collapse,
  CollapsePanel,
  TableSearchDropdownProps,
} from '../../components/Inputs'

const UsersList = ({ navigate }) => {
  // Get users
  const { data, loading, error, refetch } = useGetList()

  // Get Dicts
  const {
    data: { roles, structure },
    dictsError,
  } = useDictsForFilters()

  // Callbacks
  const create = useCallback(() => navigate('/users/new'), [navigate])
  const update = useCallback(() => refetch(), [refetch])

  // Filters and Columns
  const { filters, setFilters } = useFilters('Users')
  const columns = useMemo(
    () =>
      roles &&
      structure && [
        {
          ...TableSearchDropdownProps('name'),
          title: 'Имя',
          dataIndex: 'name',
          key: 'name',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0),
          filteredValue: filters['name'],
        },
        {
          ...TableSearchDropdownProps('login'),
          title: 'Логин',
          dataIndex: 'login',
          key: 'login',
          filteredValue: filters['login'],
        },
        {
          title: 'Роль',
          dataIndex: 'role.local_name',
          key: 'role',
          filters: roles,
          onFilter: (value, record) => record.role.local_name === value,
          filteredValue: filters['role'],
        },
        {
          ...TableSearchDropdownProps('clientChildren', (value, record) =>
            record.clientChildren.find(child =>
              child.name.toLowerCase().includes(value.toLowerCase())
            )
          ),
          title: 'Дети',
          dataIndex: 'clientChildren',
          key: 'clientChildren',
          render: value => value.map(c => c.name).join(', '),
          filteredValue: filters['clientChildren'],
        },
        {
          title: 'Группы',
          dataIndex: 'structure',
          key: 'structure',
          filters: structure,
          onFilter: (value, record) => record.structure.findIndex(c => c.id === value) > -1,
          render: value => value.map(c => c.name).join(', '),
          filteredValue: filters['structure'],
        },
        {
          ...TableSearchDropdownProps('tel'),
          title: 'Телефон',
          dataIndex: 'tel',
          key: 'tel',
          filteredValue: filters['tel'],
        },
      ],
    [roles, structure, filters]
  )

  if (error || dictsError) return <ErrorIcon />

  return (
    <>
      <TitleFixed
        text='Пользователи'
        commands={
          <ButtonGroup>
            <Button loading={loading} icon='sync' onClick={update}>
              Обновить
            </Button>
            <Button type='primary' icon='plus' onClick={create}>
              Создать
            </Button>
          </ButtonGroup>
        }
      />

      <Collapse defaultActiveKey={['active']} bordered={false} destroyInactivePanel>
        <CollapsePanel header='Активные пользователи' key='active'>
          <Table
            loading={loading}
            style={{ margin: '0 -16px', background: '#fff' }}
            dataSource={data.filter(c => c.is_active)}
            rowKey='id'
            columns={columns}
            onChange={(pagination, filters, sorter) => {
              setFilters(filters)
            }}
            onRow={record => {
              return {
                onClick: e => navigate('/users/' + record.id),
              }
            }}
          />
        </CollapsePanel>

        <CollapsePanel header='Неактивные пользователи' key='deactive'>
          <Table
            loading={loading}
            style={{ margin: '0 -16px', background: '#fff' }}
            dataSource={data.filter(c => !c.is_active)}
            rowKey='id'
            columns={columns}
            onChange={(pagination, filters, sorter) => {
              setFilters(filters)
            }}
            onRow={record => {
              return {
                onClick: e => navigate('/users/' + record.id),
              }
            }}
          />
        </CollapsePanel>
      </Collapse>
    </>
  )
}

export default UsersList
