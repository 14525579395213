import React, { useCallback, useMemo } from 'react'
import { TableSearchDropdownProps, Table, Button, ButtonGroup } from '../../components/Inputs'
import { useGetList } from '../../api/accountBalances'
import useFilters from '../../core/useFilter'
import { ErrorIcon } from '../../components/Icons'
import { TitleFixed } from '../../components/PageTemplate'
import { AccountsBalancesFilters } from './AccountsBalancesFilters'

const AccountsBalances = ({ navigate }) => {
  // Data and Filters
  const { data, loading, error, refetch } = useGetList()
  const { filters, setFilters } = useFilters('AccountsMovement')

  // Callbacks
  const update = useCallback(() => refetch(), [refetch])
  const rowKey = useCallback(record => record.account.id, [])
  const onRow = useCallback(
    record => ({
      onClick: e => {
        setFilters({ ...filters, accountId: record.account.id })
        navigate(`${record.account.id}`)
      },
    }),
    [navigate, filters, setFilters]
  )

  // Columns
  const columns = useMemo(
    () => [
      {
        ...TableSearchDropdownProps('account.name'),
        title: 'Счет',
        dataIndex: 'account.name',
        key: 'account.name',
        defaultSortOrder: 'ascend',
        sorter: (a, b) =>
          a.account.name && b.account.name ? a.account.name.localeCompare(b.account.name) : 0,
      },
      {
        title: 'Баланс',
        dataIndex: 'sum',
        key: 'sum',
        align: 'right',
        onFilter: (value, record) => (value ? record.sum >= 0 : record.sum < 0),
        filters: [
          { text: 'Положительный', value: true },
          { text: 'Отрицательный', value: false },
        ],
        render: val => (
          <span
            style={{
              color: val > 0 ? 'var(--c-positive)' : 'var(--c-danger)',
            }}
          >
            {val}
          </span>
        ),
      },
    ],
    []
  )

  if (error) return <ErrorIcon />

  return (
    <>
      <TitleFixed
        text='Баланс счетов'
        commands={
          <ButtonGroup>
            <Button loading={loading} icon='sync' onClick={update}>
              Обновить
            </Button>
          </ButtonGroup>
        }
      />
      <AccountsBalancesFilters navigate={navigate} />

      <Table loading={loading} dataSource={data} rowKey={rowKey} columns={columns} onRow={onRow} />
    </>
  )
}

export default AccountsBalances
