import React, { useEffect, useCallback, useMemo } from 'react'
import { set, get } from 'lodash'
import { withApollo } from '@apollo/client/react/hoc'
import moment from 'moment'
import cache from '../cache'

import errorNotifier from './errorNotifier'
import { useQuery, useMutation } from '@apollo/client'
import { notify, confirm } from '../components/Inputs'
import { useParams, useNavigate, useLocation } from '@reach/router'

// Вывод ошибок запроса
// Парсинг объекта по пути из objectPath
// Вывод ошибки о ненайденном объекте (если передан objectPath)
// Парсинг списка по пути из listPath
export const useQueryExt = (query, { objectPath, listPath, ...options } = {}) => {
  const result = useQuery(query, options)

  // Apollo Errors Notifications
  useEffect(() => {
    if (result.error) {
      errorNotifier(result.error)
    }
  }, [result.error])

  const qeurySuccessFinished = useMemo(
    () => !options.skip && result.called && !result.loading && !result.error,
    [result, options.skip]
  )
  const needToParseObject = useMemo(() => qeurySuccessFinished && objectPath, [
    qeurySuccessFinished,
    objectPath,
  ])
  const object = useMemo(() => (needToParseObject ? get(result.data, objectPath, null) : null), [
    needToParseObject,
    result,
    objectPath,
  ])

  // Not Found Error Notification
  useEffect(() => {
    if (needToParseObject) {
      if (object === null) {
        errorNotifier('Объект не найден')
      }
    }
  }, [needToParseObject, result.data, object])

  // Return result with parsed object
  if (needToParseObject) {
    if (object === null) {
      // Not Found
      return { ...result, error: true, data: null }
    } else {
      // Success
      return { ...result, data: object }
    }
  }

  // Return result with parsed list
  if (listPath) {
    const list = get(result.data, listPath, [])
    return { ...result, data: list }
  }

  return result
}

// Удаляет все __typename
// Уведомляет об успешной мутации
// Уведомляет об ошибках сети и мутации
// Редиректит с /new на /:id
export const useMutationExt = (query, options) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [mutate, mutationResult] = useMutation(query, options)

  const mutateExt = useCallback(
    async mutationOptions => {
      // Delete all __typenames
      const data = get(mutationOptions, 'variables.data', null)
      if (data) {
        mutationOptions.variables.data = stripTypenames(data)
      }

      // Mutation with notifications
      try {
        const result = await mutate(mutationOptions)

        notify({
          type: 'success',
          message: 'Успешно',
          description: 'Объект успешно сохранен',
        })

        if (id === 'new') {
          navigate(
            location.pathname
              .split('/')
              .filter(i => i !== 'new')
              .join('/') +
              '/' +
              data.id,
            {
              replace: true,
            }
          )
        }

        return result.data
      } catch (err) {
        console.error(err)
        errorNotifier(err)
      }
    },
    [mutate, id, navigate, location.pathname]
  )

  return [mutateExt, mutationResult]
}

// Удаляет все __typename
function stripTypenames(value) {
  if (Array.isArray(value)) {
    return value.map(stripTypenames)
  } else if (value !== null && typeof value === 'object') {
    if (moment.isMoment(value) || value instanceof Date) {
      return value
    }
    const newObject = {}
    for (const property in value) {
      if (property !== '__typename') {
        newObject[property] = stripTypenames(value[property])
      }
    }
    return newObject
  } else {
    return value
  }
}

// useRemove
// Спрашивает подтверждение перед удалением
// Выводит ошибки удаления
// Выводит сообщение об успешном удалении
// После успешного удаления редиректит на вышестоящий url, если в url есть :id
export const useRemoveExt = (query, { fieldName, ...options }) => {
  const [mutate, result] = useMutation(query, options)
  const navigate = useNavigate()
  const location = useLocation()
  const id = useMemo(() => get(options, 'variables.id', null), [options])

  const remove = useCallback(async () => {
    try {
      await confirm({
        title: 'Подтверждение',
        content: 'Уверены, что хотите удалить объект?',
        okType: 'danger',
        okText: 'Удалить',
      })
    } catch {
      return
    }

    try {
      if (id !== 'new') {
        await mutate()
      }
      notify({
        type: 'success',
        message: 'Успешно',
        description: 'Объект успешно удален',
      })

      cache.evict({ id: `${fieldName}:${id}` })
      cache.gc()

      if (id) {
        navigate(
          location.pathname
            .split('/')
            .filter(i => i !== id)
            .join('/'),
          {
            replace: true,
          }
        )
      }
    } catch (err) {
      console.error(err)
      errorNotifier(err)
    }
  }, [mutate, location, navigate, fieldName, id])

  return [remove, result]
}

// TODO
// useCreate

//КОГДА НЕТ initData
//крутит когда есть
export const withCreate = (mainArrayName, create, initDataName) => InnerComponent =>
  withApollo(props => {
    let initData = { ...cache.data.data['$ROOT_QUERY.' + initDataName] }
    cache.data.data['$ROOT_QUERY.' + initDataName] = null

    if (mainArrayName && props.id === 'new') {
      let newProps = { ...props }
      set(newProps, mainArrayName, create(initData))
      return <InnerComponent {...newProps} />
    }

    return <InnerComponent {...props} />
  })
