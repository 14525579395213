import React from 'react'
import { Formik, Form, Field } from 'formik'
import cx from 'classnames'

import { Input, Button, Password } from '../../components/Inputs'
import { ISLLink } from '../../components/PageTemplate'
import { isPwa, isIos } from '../../core/PWAConts'
import useOrientation from '../../core/useOrientation'
import { useSignin } from '../../api/signin'

import s from './style.module.css'

const LoginPage = ({ onSuccess }) => {
  const orientation = useOrientation()
  const [signin, { loading }] = useSignin(onSuccess)

  return (
    <div
      className={cx(
        s.loginPage,
        { [s.standalone]: isPwa },
        { [s.web]: !isPwa },
        { [s.ios]: isIos },
        s[orientation],
      )}
    >
      <div className={s.loginPageContent}>
        <img src='/logo.png' className={s.logo} alt='' />
        <Formik initialValues={{ login: '', password: '' }} onSubmit={signin} method='POST'>
          <Form>
            <Field component={Input} placeholder='Логин' name='login' autoComplete='username' />

            <Field
              component={Password}
              placeholder='Пароль'
              name='password'
              autoComplete='current-password'
            />

            <Button htmlType='submit' loading={loading} className={s.loginBtn}>
              Войти
            </Button>
          </Form>
        </Formik>

        <p className={s.desc}>
          Для получения логина и пароля обратитесь к администратору детского сада
        </p>
      </div>

      <div className={s.footer}>
        <ISLLink />
      </div>
    </div>
  )
}

export default LoginPage
